import { getUserId } from "helpers/localstoragehelper";
import { useGetComponentsQuery } from "../redux/api/componentsApiSlice";

export const useIsComponentEnabled = () => {
  const { data, isSuccess, isFetching } = useGetComponentsQuery(getUserId(), {
    skip: !getUserId(),
  });

  const getIsComponentEnabled = (componentName) => {
    if (!componentName) return false;
    if (isSuccess && !isFetching) {
      const foundComponent = data.find((item) => item.id === componentName);
      const isEnabled = foundComponent?.feature.enabled;
      return isEnabled;
    }
  };

  return { getIsComponentEnabled, isSuccess, isFetching };
};
