import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { Button, ButtonBase, Divider, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { localizeDate, localizeTime } from "helpers/helpers";
import { useDetailsCard } from "hooks/useDetailsCard";
import { useNavigate } from "react-router";
import StatusIcon from "../UI/StatusIcon/StatusIcon";
import {
  OrderCardHeading,
  OrderCardSub,
  OrderCardText,
} from "../UI/Typography/OrderCard";

const OrderDetails = ({ currentOrderData }) => {
  const shippingDate = `${localizeTime(
    currentOrderData.schedules[0].scheduleEarliestStartDate
  )} - ${localizeTime(currentOrderData.schedules[0].scheduleStartDate)}`;

  const navigate = useNavigate();
  const navigateToFullscreen = () =>
    navigate(`/order/${currentOrderData.orderNumber}`, {
      state: {
        ...currentOrderData,
        shippingTime: shippingDate,
      },
    });

  const { closeCard } = useDetailsCard();

  const deliveries = currentOrderData?.deliveries[0];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <OrderCardText>Order Summary</OrderCardText>
        <Box>
          <ButtonBase>
            <OpenInFullIcon
              onClick={navigateToFullscreen}
              sx={{
                padding: "12px",
              }}
            />
          </ButtonBase>
          <ButtonBase onClick={closeCard}>
            <CloseIcon
              sx={{
                padding: "12px",
              }}
            />
          </ButtonBase>
        </Box>
      </Box>
      <Divider sx={{ marginY: 1 }} />
      <Grid container spacing={2} sx={{ alignItems: "center" }}>
        <Grid item xs={6} sx={{ display: "flex" }}>
          <StatusIcon status={currentOrderData.orderStatus} />
          <OrderCardText sx={{ marginLeft: 1 }}>
            {currentOrderData.orderStatus}
          </OrderCardText>
        </Grid>
        <Grid item xs={6}>
          <OrderCardHeading>SHIPPING TYPE</OrderCardHeading>
          <OrderCardText>{currentOrderData.shippingType}</OrderCardText>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "left" }}>
          <OrderCardHeading>REQUESTED DATE</OrderCardHeading>
          <Box>
            <OrderCardSub>
              {localizeDate(currentOrderData.shippingDate)}
            </OrderCardSub>
            <OrderCardSub>{shippingDate}</OrderCardSub>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "left" }}>
          <OrderCardHeading>PLACED DATE</OrderCardHeading>
          <Box>
            <OrderCardSub>
              {localizeDate(currentOrderData.placedDate)}
            </OrderCardSub>
            <OrderCardSub>
              {localizeTime(currentOrderData.placedDate)}
            </OrderCardSub>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "left" }}>
          <OrderCardHeading>DESTINATION</OrderCardHeading>
          <Box>
            <OrderCardText>
              {currentOrderData.shippingAddress.siteName}
            </OrderCardText>
            <OrderCardSub>
              {`${currentOrderData.shippingAddress.street}`}
            </OrderCardSub>
            <OrderCardSub>
              {`${currentOrderData.shippingAddress.city}, ${currentOrderData.shippingAddress.postalCode}`}
            </OrderCardSub>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <OrderCardHeading variant="subtitle1">PRODUCT CODE</OrderCardHeading>
          <OrderCardText>
            {currentOrderData.lineItems[0].materialDescription}
          </OrderCardText>
        </Grid>
        <Grid item xs={6}>
          <OrderCardHeading variant="subtitle1">ORDERED QTY</OrderCardHeading>
          <OrderCardText>
            {currentOrderData.lineItems[0].quantity}t
          </OrderCardText>
        </Grid>
        <Grid item xs={6}>
          <OrderCardHeading variant="subtitle1">PLANT</OrderCardHeading>
          <OrderCardText>{currentOrderData.orgUnit.name || "-"}</OrderCardText>
        </Grid>
      </Grid>
      <Divider sx={{ marginY: 2 }} />
      {deliveries && (
        <Box>
          <Button
            variant="outlined"
            fullWidth
            startIcon={<DownloadIcon />}
            sx={{ textTransform: "none", fontWeight: 700, marginBottom: 2 }}
          >
            Download All Deliveries Notes
          </Button>
          <Button
            onClick={navigateToFullscreen}
            variant="contained"
            fullWidth
            sx={{ textTransform: "none", fontWeight: 700 }}
          >
            View All Deliveries
          </Button>
        </Box>
      )}
    </>
  );
};

export default OrderDetails;
