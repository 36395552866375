import SearchIcon from "@mui/icons-material/Search";
import { Box, IconButton, TextField } from "@mui/material";
import {
  getDefaultCustomerFromLS,
  getUserId,
  setObjectToLS,
} from "helpers/localstoragehelper";
import { useActions } from "hooks/useActions";
import { useEffect, useState } from "react";
import { useGetCustomerQuery } from "../../../../redux/api/orderListApiSlice";

const CustomerNumberFilter = () => {
  const { setQueryParams, setCurrentCustomer } = useActions();

  const localStorageCustomer = getDefaultCustomerFromLS();

  const [customerNumberValue, setCustomerNumberValue] = useState(
    localStorageCustomer?.customerName || ""
  );
  const handleCustomerNumberChange = (e) =>
    setCustomerNumberValue(e.target.value);

  const [customerNumber, setCustomerNumber] = useState(
    localStorageCustomer?.customerNumber || ""
  );
  const sendCustomerNumber = () => setCustomerNumber(customerNumberValue);
  const clear = () => setCustomerNumberValue("");

  const { data, isFetching } = useGetCustomerQuery(customerNumber, {
    skip: !customerNumber,
  });

  useEffect(() => {
    if (data?.length) {
      setCustomerNumberValue(data[0].customerName);
      setQueryParams({ customerId: data[0].customerId });
      setCurrentCustomer(data[0]);
      setObjectToLS(`default-customer-${getUserId()}`, data[0]);
    }
  }, [data, setQueryParams, setCurrentCustomer]);

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexShrink: 0,
        backgroundColor: theme.palette.inputBackgroundColor.main,
        border: `1px solid ${theme.palette.inputBorderColor.main}`,
        borderRadius: "4px",
        "&:hover": {
          background: "#e7e7e7",
        },
      })}
    >
      <TextField
        onFocus={clear}
        disabled={isFetching}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            sendCustomerNumber();
          }
        }}
        label="Enter customer number"
        value={customerNumberValue}
        onChange={handleCustomerNumberChange}
        variant="filled"
        sx={{
          "& .MuiInputBase-root": {
            background: "none",
            border: "none",
          },
        }}
        InputProps={{
          disableUnderline: true,
          sx: {
            background: "none",
            borderRadius: 0,
            "&:hover": {
              background: "none",
            },
          },
        }}
      />
      <Box
        sx={(theme) => ({
          display: "flex",
          borderLeft: `1px solid ${theme.palette.inputBorderColor.main}`,
        })}
      >
        <IconButton
          onClick={sendCustomerNumber}
          sx={{
            "&:hover": {
              background: "none",
            },
          }}
        >
          <SearchIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CustomerNumberFilter;
