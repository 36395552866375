import { Box } from "@mui/material";
import { OverviewBoxHeadingStyled, OverviewBoxStyled } from "../Shared";
import CreditWidgetBrackets from "./CreditWidgetBrackets";
import CreditWidgetColorBar from "./CreditWidgetColorBar";
import CreditWidgetLegend from "./CreditWidgetLegend";
import CreditWidgetTopText from "./CreditWidgetTopText";
import {
  dispatchedColor,
  notDispatchedColor,
  unpaidColor,
} from "constants/constants";

const CreditWidgetInstance = ({ creditData, payer }) => {
  return (
    <OverviewBoxStyled sx={{ marginBottom: 2 }}>
      <CreditWidgetTopText payer={payer} payerNumber={creditData.payerNumber} />
      <CreditWidgetBrackets creditData={creditData} />
      <Box
        sx={{
          display: "flex",
          height: "10px",
          width: "100%",
          background: "black",
          overflow: "clip",
          marginBottom: 2,
        }}
      >
        <CreditWidgetColorBar
          color={unpaidColor}
          amount={creditData.receivables}
          total={creditData.total}
        />
        <CreditWidgetColorBar
          color={dispatchedColor}
          amount={creditData.deliveredNotBilled}
          total={creditData.total}
        />
        <CreditWidgetColorBar
          color={notDispatchedColor}
          amount={creditData.orderedNotDelivered}
          total={creditData.total}
        />
      </Box>
      <Box>
        <OverviewBoxHeadingStyled>
          No payments received in the last 30 day
        </OverviewBoxHeadingStyled>
        <Box
          sx={{
            display: "flex",
            "&>div": { marginY: 1, marginRight: 2 },
          }}
        >
          <CreditWidgetLegend
            name="Unpaid"
            amount={creditData.receivables}
            currency={creditData.currency}
            color={unpaidColor}
          />
          <CreditWidgetLegend
            name="Not Yet Invoiced"
            amount={creditData.deliveredNotBilled}
            currency={creditData.currency}
            color={dispatchedColor}
            dispatched="Dispatched"
          />
          <CreditWidgetLegend
            name="Not Yet Invoiced"
            amount={creditData.orderedNotDelivered}
            currency={creditData.currency}
            color={notDispatchedColor}
            dispatched="Not Yet Dispatched"
          />
          <CreditWidgetLegend
            name="Credit left"
            amount={creditData.remainingCredit}
            currency={creditData.currency}
            color="black"
            exceededBy={creditData.exceededLimit}
          />
        </Box>
      </Box>
    </OverviewBoxStyled>
  );
};

export default CreditWidgetInstance;
