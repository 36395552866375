import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { useActions } from "../../../../hooks/useActions";
import "moment/locale/ru";
import "moment/locale/en-gb";

const HubDatePicker = ({ deliveryDate }) => {
  const currentOption = useSelector((state) => state.deliveryOI.currentOption);
  const deliveryDates = useSelector((state) => state.deliveryOI.deliveryDates);
  const tableRows = useSelector((state) => state.deliveryOI.tableRows);

  const isCutOffTime = useSelector((state) => state.deliveryOI.isCutOffTime);
  const currentMaterial = useSelector(
    (state) => state.deliveryOI.currentMaterial
  );
  const { setCurrentMaterial } = useActions();

  const availableMoments = useMemo(
    () =>
      deliveryDates.length ? deliveryDates.map((item) => moment(item)) : [],
    [deliveryDates]
  );

  const enableDates = (date) =>
    !availableMoments?.some((item) => item.isSame(date));
  const [currentDate, setCurrentDate] = useState(moment(deliveryDate));

  useEffect(() => {
    setCurrentDate(moment(deliveryDate));
  }, [deliveryDate]);

  useEffect(() => {
    tableRows.length === 0 &&
      setCurrentDate(
        moment(
          currentOption.businessDays[
            isCutOffTime ? "daysAfterCutOffTime" : "daysBeforeCutOffTime"
          ][0]
        )
      );
  }, [currentOption.businessDays, isCutOffTime]);

  const { setValue } = useFormContext();
  useEffect(() => {
    const formattedDate = currentDate.format("YYYY-MM-DD");
    const siteByDate = currentOption.options.find((site) =>
      site.businessDays[
        isCutOffTime ? "daysAfterCutOffTime" : "daysBeforeCutOffTime"
      ].find((date) => date === formattedDate)
    );

    const materialByDate =
      siteByDate &&
      siteByDate.materials.find(
        (material) =>
          material.materialEnteredNumber ===
          currentMaterial?.materialEnteredNumber
      );

    materialByDate && setCurrentMaterial(materialByDate);

    setValue("deliveryDate", currentDate.toISOString());
  }, [setValue, currentDate]);

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={navigator.language}
    >
      <DatePicker
        value={currentDate}
        onAccept={(date) => setCurrentDate(moment(date))}
        shouldDisableDate={enableDates}
        label="Date"
        sx={{ width: "100%" }}
        slotProps={{
          textField: {
            required: true,
            id: "oi-delivery-form-date",
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default HubDatePicker;
