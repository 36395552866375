import { createSlice } from "@reduxjs/toolkit";
import { today, weekFromToday } from "constants/dates";
import { getDefaultCustomerFromLS } from "helpers/localstoragehelper";

const localStorageCustomer = getDefaultCustomerFromLS();

const initialState = {
  isCardOpen: false,
  orderId: undefined,
  currentCustomer: localStorageCustomer || {},
  queryParams: {
    customerId: "",
    siteId: "",
    shippingDate: today.toISOString(),
    endDate: weekFromToday.toISOString(),
    limit: 10,
    offset: 0,
  },
};

const orderListSlice = createSlice({
  name: "orderList",
  initialState,
  reducers: {
    setCurrentCustomer: (state, action) => {
      state.currentCustomer = action.payload;
    },
    setQueryParams: (state, { payload }) => {
      state.queryParams = {
        ...state.queryParams,
        ...payload,
      };
    },
    setIsCardOpen: (state, action) => {
      state.isCardOpen = action.payload;
    },
    setOrderId: (state, action) => {
      state.orderId = action.payload;
    },
  },
});

export const { actions } = orderListSlice;
export default orderListSlice.reducer;
