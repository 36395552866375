import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useActions } from "./useActions";

export const useDetailsCard = () => {
  const isCardOpen = useSelector((state) => state.orderList.isCardOpen);
  const { setIsCardOpen } = useActions();

  const openCard = () => setIsCardOpen(true);
  const closeCard = useCallback(() => setIsCardOpen(false), [setIsCardOpen]);

  return { isCardOpen, closeCard, openCard };
};
