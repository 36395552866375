import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { useMemo } from "react";
import { actions as orderListActions } from "../redux/slices/orderList/orderList";
import { actions as deliveryOIActions } from "../redux/slices/deliveryOI/deliveryOI";
import { actions as generalActions } from "../redux/slices/generalSlice";

const rootActions = {
  ...orderListActions,
  ...deliveryOIActions,
  ...generalActions,
};

export function useActions() {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(rootActions, dispatch), [dispatch]);
}
