import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, ButtonBase, Grid, Paper, Stack, Typography } from "@mui/material";
import Popover from "@mui/material/Popover";
import { useState } from "react";
import PaymentStatusPopover from "./PaymentStatusPopover";

const PaymentStatusFilter = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverWidth, setPopoverWidth] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    setPopoverWidth(e.currentTarget.offsetWidth);
  };
  const handleClose = () => setAnchorEl(null);

  //   const initialChoices = {
  //     "All Status": true,
  //     Open: false,
  //     "Fully Paid": false,
  //     "Partially Paid": false,
  //   };

  const statusArray = ["All Status", "Open", "Fully Paid", "Partially Paid"];

  const [choices, setChoices] = useState(statusArray);

  const popoverContentProps = {
    popoverWidth,
    setChoices,
    choices,
    statusArray,
  };

  return (
    <Grid item>
      <ButtonBase
        onClick={handleClick}
        sx={{
          display: "block",
          width: "100%",
        }}
      >
        <Paper
          square={false}
          elevation={0}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: (theme) => theme.palette.inputBackgroundColor.main,
            border: (theme) =>
              `1px solid ${theme.palette.inputBorderColor.main}`,
            padding: "5px",
            paddingBottom: "8px",
            paddingLeft: "12px",
            "&:hover": {
              background: "#E7E7E7",
            },
          }}
        >
          <Box maxWidth="90%" minWidth="222px">
            <Stack textAlign="left">
              <Typography variant="caption" color="text.secondary">
                Payment Status
              </Typography>
              <Typography variant="body1" noWrap>
                {`${choices[0]} ${
                  choices.length > 1 && choices[0] !== "All Status"
                    ? " + 1"
                    : ""
                }`}
              </Typography>
            </Stack>
          </Box>
          <Box>
            <ArrowDropDownIcon />
          </Box>
        </Paper>
      </ButtonBase>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ marginTop: 1 }}
      >
        <PaymentStatusPopover {...popoverContentProps} />
      </Popover>
    </Grid>
  );
};

export default PaymentStatusFilter;
