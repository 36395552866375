import { Box, Tooltip } from "@mui/material";
import * as statuses from "../../../assets/img/statuses/statusImages";

const StatusIcon = ({ status = "unconfirmed", small = false }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Tooltip title={status}>
        <img
          src={statuses[status]}
          alt={status}
          width={small ? "16px" : "24px"}
        />
      </Tooltip>
    </Box>
  );
};

export default StatusIcon;
