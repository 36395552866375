import { Outlet } from "react-router-dom";
import AppHeader from "../AppHeader/AppHeader";

const Layout = () => {
  return (
    <AppHeader>
      <Outlet />
    </AppHeader>
  );
};

export default Layout;
