import { styled, Typography } from "@mui/material";

export const CaptionHeading = styled((props) => (
  <Typography variant="caption" color="text.secondary" {...props} />
))(() => ({
  fontSize: "14px",
}));

export const SecondaryColorHeading = styled((props) => (
  <Typography color="text.headings" variant="h6" {...props} />
))(() => ({
  fontWeight: 600,
}));

export const ConfirmationText = styled((props) => <Typography {...props} />)(
  () => ({
    fontSize: "14px",
    lineHeight: "24px",
  })
);

export const PinCodeHeadings = styled((props) => <Typography {...props} />)(
  () => ({
    fontSize: "16px",
    fontWeight: 500,
  })
);
