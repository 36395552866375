import { useSelector } from "react-redux";
import { useGetOrderListQuery } from "../redux/api/orderListApiSlice";
import { useGetInvoicesQuery } from "../redux/api/invoicesListApiSlice";
import usePageType from "./usePageType";
import {
  InvoiceItem,
  OrderItem,
} from "../Components/UI/PageComponents/ContentTable/ContentTableBody/ContentTableItems";

export const useGetTableData = () => {
  const query = useSelector((state) => state.orderList.queryParams);
  const PageType = usePageType();
  const isInvoices = PageType === "Invoices";

  const {
    data: invoiceData,
    isSuccess: isSuccessInvoiceData,
    isFetching: isFetchingInvoiceData,
    isLoading: isLoadingInvoiceData,
    refetch: refetchInvoiceData,
  } = useGetInvoicesQuery(query, {
    skip: !query?.customerId || !isInvoices,
  });

  const {
    data: OrderData,
    isSuccess: isSuccessOrderData,
    isFetching: isFetchingOrderData,
    isLoading: isLoadingOrderData,
    refetch: refetchOrderData,
  } = useGetOrderListQuery(query, {
    skip: !query?.customerId || isInvoices,
  });

  return {
    isInvoices,
    query,
    data: isInvoices ? invoiceData : OrderData,
    isSuccess: isInvoices ? isSuccessInvoiceData : isSuccessOrderData,
    isFetching: isInvoices ? isFetchingInvoiceData : isFetchingOrderData,
    isLoading: isInvoices ? isLoadingInvoiceData : isLoadingOrderData,
    refetch: isInvoices ? refetchInvoiceData : refetchOrderData,
    handleItem: isInvoices ? InvoiceItem : OrderItem,
  };
};
