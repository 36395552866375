import { Box, Card, CardContent, Skeleton } from "@mui/material";

const SkeletonCard = () => {
    return (
        <Card variant="outlined">
            <CardContent sx={{
                padding: '24px 16px',
                textAlign: 'left',
                backgroundColor: 'white',
                minHeight: '500px'
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch'
                }}>
                    <Box display='flex' sx={{ marginBottom: '10px' }}>
                        <Skeleton variant="circular" width={40} height={40} />
                        <Skeleton variant="text" sx={{ width: '100%', marginLeft: '10px' }} />
                    </Box>
                    <Skeleton variant="rectangular" sx={{ width: '100%', height: '60px', marginBottom: '10px' }} />
                    <Skeleton variant="rectangular" sx={{ width: '100%', height: '60px', marginBottom: '10px' }} />
                </Box>
            </CardContent>
        </Card>
    )
}

export default SkeletonCard;