import { styled, Typography } from "@mui/material";

export const OrderTableText = styled((props) => <Typography {...props} />)(
  ({ theme }) => ({
    fontWeight: 400,
    fontSize: "14px",
    color: theme.palette.text.primary,
  })
);

export const OrderTableCaption = styled((props) => <Typography {...props} />)(
  () => ({
    fontWeight: 500,
    color: "#7f9ba6",
    fontSize: "14px",
  })
);
