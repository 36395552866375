import { Box, Typography } from "@mui/material";
import { CaptionHeading } from "Components/UI/Typography/SharedTypography";

const NotFound = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      margin: "auto",
      padding: 4,
    }}
  >
    <Typography
      sx={{
        fontSize: { xs: "100px", md: "200px" },
        color: "rgba(0, 0, 0, .5)",
      }}
    >
      404
    </Typography>
    <Box>
      <CaptionHeading sx={{ fontWeight: 700, fontSize: "16px" }}>
        Sorry... something went wrong.{" "}
      </CaptionHeading>
      <CaptionHeading sx={{ fontSize: "16px" }}>
        This page was not found. Please, contact administrators.
      </CaptionHeading>
    </Box>
  </Box>
);

export default NotFound;
