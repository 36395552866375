import { Box, Button, styled, Typography } from "@mui/material";
import { getUserId } from "helpers/localstoragehelper";
import { useActions } from "hooks/useActions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetUserQuery } from "../../redux/api/userApiSlice";
import {
  CaptionHeading,
  MainColorHeading,
} from "../UI/Typography/SharedTypography";
import OIPinCodeDetails from "./OIPinCodeDetails";

const AdditionalInfoBox = styled((props) => (
  <Box marginBottom={1} {...props} />
))(() => ({}));

const DeliveryOIAdditionalInfo = ({ isCollect }) => {
  const navigate = useNavigate();
  const { data } = useGetUserQuery(getUserId());

  const { setCurrentOption } = useActions();

  const currentOption = useSelector((state) => state.deliveryOI.currentOption);
  const backToOrderList = () => {
    setCurrentOption(currentOption);
    navigate("/orders");
  };

  return (
    <>
      {isCollect && <OIPinCodeDetails />}
      <MainColorHeading marginBottom={2}>Additional Info</MainColorHeading>

      <AdditionalInfoBox>
        <CaptionHeading>
          {isCollect ? "Collection Address" : "Deliver to"}
        </CaptionHeading>
        <Typography> {`${currentOption?.siteName}`}</Typography>
        <Typography>{`${currentOption?.street}`}</Typography>
        <Typography>{`${currentOption?.city} ${currentOption?.postalCode}`}</Typography>
      </AdditionalInfoBox>

      <AdditionalInfoBox>
        <CaptionHeading>Contact person</CaptionHeading>
        <Typography>{data?.name}</Typography>
      </AdditionalInfoBox>

      <AdditionalInfoBox>
        <CaptionHeading>Main Phone</CaptionHeading>
        <Typography>
          {currentOption?.contact?.mainPhone === "UNDEFINED"
            ? "n\\a"
            : currentOption?.contact?.mainPhone}
        </Typography>
      </AdditionalInfoBox>

      <AdditionalInfoBox>
        <CaptionHeading>Cancellation</CaptionHeading>
        <Typography>
          Changes or cancellations are only possible by phone
        </Typography>
      </AdditionalInfoBox>

      <Box marginTop={4}>
        <Button variant="contained" fullWidth onClick={backToOrderList}>
          Go To Orders List
        </Button>
      </Box>
    </>
  );
};

export default DeliveryOIAdditionalInfo;
