import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Autocomplete, TextField } from "@mui/material";
import { useEffect } from "react";
import { timeOptions } from "../../../constants/constants";
import {
  addSemicolon,
  findClosestTime,
  findCurrentIndex,
} from "../../../helpers/helpers";
import ButtonWithTooltip from "../ButtonWithTooltip/ButtonWithTooltip";

const TimeInput = ({
  label = "time",
  time,
  setTime,
  id,
  setIndex,
  index,
  options,
}) => {
  const addTime = () => {
    setIndex((prev) => (prev >= timeOptions.length - 1 ? prev : prev + 1));
  };

  const subtractTime = () => {
    setIndex((prev) => (prev <= 0 ? prev : prev - 1));
  };

  const handleTextInput = (timeString) => {
    const closestTime = findClosestTime(timeString);
    setIndex(findCurrentIndex(closestTime));
    setTime(closestTime);
  };

  useEffect(() => {
    setTime(timeOptions[index]?.label24h);
  }, [index, setTime]);

  return (
    <Autocomplete
      id={`oi-delivery-form-${id}`}
      inputValue={time}
      options={options}
      freeSolo
      filterOptions={(option) => option}
      onInputChange={(_, value) => setTime(addSemicolon(value))}
      onBlur={(e) => handleTextInput(e.target.value)}
      onChange={(_, value) => {
        if (value) handleTextInput(value.label24h);
      }}
      getOptionLabel={(option) => option.label24h}
      renderInput={(params) => {
        return (
          <TextField
            id={params.id}
            label={label}
            variant="filled"
            sx={{ width: "100%" }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: {
                padding: 0,
                alignItems: "stretch",
              },
              startAdornment: (
                <ButtonWithTooltip tooltipText={false} onClick={subtractTime}>
                  <KeyboardArrowLeftIcon />
                </ButtonWithTooltip>
              ),
              endAdornment: (
                <ButtonWithTooltip tooltipText={false} onClick={addTime}>
                  <KeyboardArrowRightIcon />
                </ButtonWithTooltip>
              ),
            }}
            inputProps={{
              ...params.inputProps,
              maxLength: 5,
              style: {
                textAlign: "center",
                width: "100%",
                padding: "25px 0 8px 0",
              },
            }}
            InputLabelProps={{
              ...params.InputLabelProps,
              style: {
                top: "8%",
                left: "50%",
                transform: "translate(-50%, 0)",
                fontSize: "12px",
              },
            }}
          />
        );
      }}
    />
  );
};

export default TimeInput;
