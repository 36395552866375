import ReceiptIcon from "@mui/icons-material/Receipt";
import { Box, Button, styled, Typography } from "@mui/material";
import { useState } from "react";
import { rowsPerPage } from "../../../constants/constants";

export const OrderTablePaginationButton = styled((props) => (
  <Button {...props} />
))(() => ({}));

const OrderTablePagination = ({ handlePage }) => {
  const [pageSize, setPageSize] = useState(10);

  const handlePageSize = (pageSize) => {
    const limit = +pageSize.target.innerText;
    handlePage({ page: 0, pageSize: limit });
    setPageSize(limit);
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        marginX: "-16px",
        marginBottom: 4,
        padding: "10px 32px",
        background: (theme) => theme.palette.inputBackgroundColor.main,
      }}
    >
      <Box>
        <Typography display="inline">Entries per page:</Typography>
        {rowsPerPage.map((size) => (
          <OrderTablePaginationButton
            key={size}
            onClick={handlePageSize}
            sx={{ fontWeight: pageSize === size ? 700 : 500 }}
          >
            {size}
          </OrderTablePaginationButton>
        ))}
      </Box>
      <Button startIcon={<ReceiptIcon sx={{ paddingBottom: "2px" }} />}>
        Export
      </Button>
    </Box>
  );
};

export default OrderTablePagination;
