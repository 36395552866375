import { Box, Tooltip, Typography } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import {
  deliverTruckBulk,
  pickupTruckBulk,
} from "../../../assets/img/statuses/statusImages";
import { localizeTime } from "../../../helpers/helpers";
import { useActions } from "../../../hooks/useActions";
import { useGetOrderListQuery } from "../../../redux/api/orderListApiSlice";
import StatusIcon from "../../UI/StatusIcon/StatusIcon";
import {
  OrderTableCaption,
  OrderTableText,
} from "../../UI/Typography/OrderList";
import { rowsPerPage } from "../../../constants/constants";
import { useTranslation } from "react-i18next";

const dateComparator = (a, b) =>
  new Date(a.date).getDate() - new Date(b.date).getDate();

const OrderTableBody = ({ paginationModel, setPaginationModel }) => {
  const { t } = useTranslation();
  console.log(t("orderTable.shippingDate"));

  const apiRef = useGridApiRef();

  const openOrderCard = ({ id }) => {
    setOrderId(id - 1);
    setIsCardOpen(true);
  };

  const { setOrderId, setIsCardOpen } = useActions();
  const query = useSelector((state) => state.orderList.queryParams);

  const { isFetching, data } = useGetOrderListQuery(query, {
    skip: !query.customerId,
  });

  const columns = [
    {
      field: "shippingDate",
      // headerName: "Requested Date",
      headerName: t("orderTable.shippingDate"),
      sortComparator: dateComparator,
      renderCell: (params) => (
        <Box>
          <OrderTableText sx={{ fontWeight: 500 }}>
            {new Intl.DateTimeFormat().format(new Date(params.value.date))}
          </OrderTableText>
          <Typography>
            {`${localizeTime(params.value.startTime)} - ${localizeTime(
              params.value.endTime
            )}`}
          </Typography>
        </Box>
      ),
      flex: 0,
    },
    {
      field: "po-order",
      // headerName: "PO#/ORDER#",
      headerName: t("orderTable.po-order"),
      maxWidth: 200,
      renderCell: (params) => (
        <Box>
          {/* <OrderTableText>{params.value.index}</OrderTableText> */}
          <OrderTableText>{params.value.customerReference}</OrderTableText>
          <OrderTableCaption>{params.value.orderNumber}</OrderTableCaption>
        </Box>
      ),
      flex: 1,
      sortable: false,
    },
    {
      field: "destination",
      // headerName: "Destination",
      headerName: t("orderTable.destination"),
      renderCell: (params) => (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <OrderTableText>{params.value.siteName}</OrderTableText>
          <OrderTableCaption>{params.value.street}</OrderTableCaption>
        </Box>
      ),
      flex: 1,
      sortable: false,
    },
    {
      field: "material",
      // headerName: "Material",
      headerName: t("orderTable.material"),
      renderCell: (params) => <OrderTableText>{params.value}</OrderTableText>,
      flex: 0,
      sortable: false,
    },
    {
      field: "ordered-delivered",
      // headerName: "Ordered/Delivered",
      headerName: t("orderTable.ordered-delivered"),
      renderCell: ({ value }) => {
        const isShippingTypeCollect = value.shippingType === "collect";
        const shippingType = isShippingTypeCollect
          ? pickupTruckBulk
          : deliverTruckBulk;

        const tooltipTitle = `${
          isShippingTypeCollect ? "Pick-up" : "Delivery"
        } by bulk carrier`;

        return (
          <Box sx={{ display: "flex", columnGap: "5px" }}>
            <OrderTableText>{value.quantity} t</OrderTableText>
            <Tooltip title={tooltipTitle}>
              <img src={shippingType} alt={shippingType} width="24px" />
            </Tooltip>
          </Box>
        );
      },
      flex: 0,
      sortable: false,
    },
    {
      field: "status",
      // headerName: "Status",
      headerName: t("orderTable.status"),
      renderCell: (params) => <StatusIcon status={params.value} />,
      flex: 0,
      maxWidth: 60,
      sortable: false,
    },
  ];

  const rows =
    data &&
    data.results.map((order, index) => {
      return {
        id: index + 1,
        shippingDate: {
          date: order.shippingDate,
          startTime: order.schedules[0].scheduleEarliestStartDate,
          endTime: order.schedules[0].scheduleStartDate,
        },
        "po-order": {
          customerReference: order.customerReference,
          orderNumber: order.orderNumber,
          index,
        },
        destination: {
          siteName: order.shippingAddress.siteName,
          street: order.shippingAddress.street,
        },
        material: order.lineItems[0].materialDescription,
        "ordered-delivered": {
          quantity: order.lineItems[0].quantity,
          shippingType: order.shippingType,
        },

        status: order.orderStatus,
      };
    });

  return (
    <DataGrid
      autoHeight
      checkboxSelection
      disableRowSelectionOnClick
      disableColumnMenu
      disableColumnResize
      rowCount={data?.count || -1}
      paginationMode="server"
      columnHeaderHeight={40}
      rows={rows || []}
      loading={isFetching}
      apiRef={apiRef}
      columns={columns}
      getRowHeight={() => "auto"}
      pageSizeOptions={rowsPerPage}
      onRowClick={openOrderCard}
      sortingOrder={["desc", "asc", null]}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 10 },
        },
      }}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      sx={{
        bgcolor: "white",
        "& .MuiDataGrid-row:hover": {
          cursor: "pointer",
        },
        "& .MuiDataGrid-columnHeader": {
          backgroundColor: "rgb(245, 246, 247)",
          textAlign: "center",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontSize: "10px",
          textTransform: "uppercase",
          fontWeight: 700,
        },
        "& .MuiDataGrid-cell": {
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          color: "text.primary",
        },
        "& .MuiDataGrid-cellCheckbox": {
          paddingTop: "8px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        },
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
          outline: "none",
        },
        "&.MuiDataGrid-root .MuiDataGrid-row:hover": {
          backgroundColor: "rgb(245, 246, 247)",
        },
      }}
    />
  );
};

export default OrderTableBody;
