import { apiSlice } from "./apiSlice";
const LOGIN = "login/";
const LOGOUT = "logout/";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: LOGIN,
        method: "POST",
        body: { ...credentials },
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: LOGOUT,
        method: "POST",
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        await queryFulfilled;
        localStorage.clear();
      },
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation } = authApiSlice;
