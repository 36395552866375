import EastIcon from "@mui/icons-material/East";
import FilterListIcon from "@mui/icons-material/FilterList";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import SearchIcon from "@mui/icons-material/Search";
import { Button, ButtonBase, Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { destination, hourglass, tontruck } from "../../../assets/img/images";
import { useGetOrderListQuery } from "../../../redux/api/orderListApiSlice";
import SkeletonCard from "../../UI/SkeletonCard/SkeletonCard";
import { localizeDate, localizeTime } from "../../../helpers/helpers";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const MobileOrderCardContent = ({ order }) => {
  const shippingDate = `${localizeTime(
    order.schedules[0].scheduleEarliestStartDate
  )} - ${localizeTime(order.schedules[0].scheduleStartDate)}`;

  const navigate = useNavigate();
  const navigateToFullscreen = () =>
    navigate(`/order/${order.orderNumber}`, {
      state: {
        ...order,
        shippingTime: shippingDate,
      },
    });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexGrow: 1,
          marginBottom: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img src={hourglass} alt="hourglass" height="16px" />
          <Box sx={{ margin: "4px" }} />
          <Typography variant="h6">{order.orderStatus}</Typography>
        </Box>
        <ButtonBase onClick={navigateToFullscreen}>
          <OpenInFullIcon />
        </ButtonBase>
      </Box>
      <Box sx={{ marginBottom: "10px" }}>
        <Typography>REQUESTED DATE</Typography>
        <Box>
          <Box>
            <Typography variant="h6">
              {localizeDate(order.shippingDate)}
            </Typography>
            <Typography variant="body2">{shippingDate}</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ marginBottom: "10px" }}>
        <Typography>PLACED DATE</Typography>
        <Box>
          <Box>
            <Typography variant="h6">
              {localizeDate(order.placedDate)}
            </Typography>
            <Typography variant="body2">
              {localizeTime(order.placedDate)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          marginBottom: "10px",
        }}
      >
        <Typography>PURCHASE ORDER #</Typography>
        <Box>
          <Typography variant="h6">{order.customerReference}</Typography>
          <Typography variant="body2">{order.orderNumber}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          marginBottom: "10px",
        }}
      >
        <Typography>MATERIAL & VOLUME</Typography>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">
              {order.lineItems[0].materialDescription}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingRight: "64px",
              }}
            >
              <Typography variant="body2">
                {order.lineItems[0].quantity}t
              </Typography>
              <Box sx={{ margin: "4px" }} />
              <img src={tontruck} alt="truck" height="24px" />
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box>
        <Box>
          <img src={destination} alt="truck" height="24px" />
        </Box>
        <Box>
          <Box>
            {order.customer.customerName}
            <Typography variant="body2">
              {`${order.shippingAddress.street} ${order.shippingAddress.city}, ${order.shippingAddress.postalCode}`}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <Button
          onClick={navigateToFullscreen}
          startIcon={<EastIcon />}
          fullWidth
          variant="outlined"
        >
          View details
        </Button>
      </Box>
    </Box>
  );
};

const MobileOrderCard = () => {
  const query = useSelector((state) => state.orderList.queryParams);

  const { isLoading, data: orderData } = useGetOrderListQuery(query, {
    skip: !query.customerId,
  });

  return (
    <Box sx={{ minWidth: 275, maxWidth: 900, backgroundColor: "white" }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          padding: "8px 16px",
        }}
      >
        <Box>
          <Typography variant="h6">{`${orderData?.results?.length} item(s) listed`}</Typography>
        </Box>
        <Box>
          <ButtonBase>
            <SearchIcon sx={{ width: "35px", height: "35px" }} />
          </ButtonBase>
          <ButtonBase>
            <FilterListIcon sx={{ width: "35px", height: "35px" }} />
          </ButtonBase>
        </Box>
      </Box>
      <Divider
        sx={{
          margin: "0 16px",
        }}
      />
      {isLoading && <SkeletonCard />}
      {orderData &&
        orderData.results.map((order) => (
          <Card
            key={order.orderNumber}
            variant="outlined"
            sx={{
              margin: "20px 16px",
              textAlign: "left",
              minHeight: "500px",
            }}
          >
            <CardContent>
              <MobileOrderCardContent order={order} />
            </CardContent>
          </Card>
        ))}
    </Box>
  );
};

export default MobileOrderCard;
