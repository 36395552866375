import { Box, Typography } from "@mui/material";
import { noOrderInCard } from "../../../assets/img/images";

const NoDataOverlay = () => {
  return (
    <Box
      sx={{
        margin: "auto",
        textAlign: "center",
      }}
    >
      <Box>
        <Box>
          <img
            src={noOrderInCard}
            alt="choose order"
            width="auto"
            height="auto"
          />
        </Box>
        <Box>
          <Typography>
            Select an order from left side list to see the order details here
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NoDataOverlay;
