import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { localizeDate } from "helpers/helpers";

const ContentTableSearchInfo = () => {
  const query = useSelector((state) => state.orderList.queryParams);
  const currentCustomerName = useSelector(
    (state) => state.orderList.currentCustomer
  )?.customerName;

  if (!currentCustomerName) return null;

  return (
    <Typography
      sx={{
        fontWeight: 700,
        marginBottom: 3,
        fontSize: "18px",
        paddingLeft: 2,
      }}
    >
      Results for {currentCustomerName}, {localizeDate(query.shippingDate)}-
      {localizeDate(query.endDate)}
    </Typography>
  );
};

export default ContentTableSearchInfo;
