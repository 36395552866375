import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Button, ButtonBase, Divider, Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import FullScreenDeliveriesContainer from "Components/FullscreenOrder/FullScreenDeliveriesContainer";
import FullScreenGridItem from "Components/FullscreenOrder/FullScreenGridItem";
import StatusIcon from "Components/UI/StatusIcon/StatusIcon";
import {
  OrderCardHeading,
  OrderCardText,
} from "Components/UI/Typography/OrderCard";
import { PageHeading } from "Components/UI/Typography/SharedTypography";
import { localizeDate, localizeTime } from "helpers/helpers";
import { useIsDesktop } from "hooks/useIsDesktop";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

const iconStyles = { display: "flex", columnGap: "5px" };

const FullscreenOrder = () => {
  const { state: currentOrderData } = useLocation();
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();

  if (!currentOrderData) return <Navigate to="/orders" replace={true} />;

  const goToOrderList = () => navigate("/orders");

  const deliveries = currentOrderData?.deliveries[0];
  return (
    <Box sx={{ paddingX: { xs: 1, xl: 4 }, paddingBottom: 4 }}>
      <Box sx={{ paddingY: 2 }}>
        <ButtonBase
          onClick={goToOrderList}
          variant="outlined"
          sx={{ color: "rgba(255, 255, 255, 0.6)" }}
        >
          <KeyboardArrowLeftIcon fontSize="small" />
          Back to List of Orders
        </ButtonBase>
        <PageHeading>Order Details</PageHeading>
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={12} xl={9}>
          <Paper sx={{ padding: 4 }}>
            <Grid container>
              <Grid item xs={isDesktop ? 9 : 12}>
                <Grid container spacing={2}>
                  <FullScreenGridItem>
                    <OrderCardHeading>Order #</OrderCardHeading>
                    <OrderCardText>
                      {currentOrderData.orderNumber}
                    </OrderCardText>
                  </FullScreenGridItem>
                  <FullScreenGridItem>
                    <OrderCardHeading>Status</OrderCardHeading>
                    <Box sx={iconStyles}>
                      <StatusIcon small />
                      <OrderCardText>
                        {currentOrderData.orderStatus}
                      </OrderCardText>
                    </Box>
                  </FullScreenGridItem>
                  <FullScreenGridItem>
                    <OrderCardHeading>Placed Date</OrderCardHeading>
                    <OrderCardText>
                      {localizeDate(currentOrderData.placedDate)}
                    </OrderCardText>
                    <OrderCardText>
                      {localizeTime(currentOrderData.placedDate)}
                    </OrderCardText>
                  </FullScreenGridItem>
                  <FullScreenGridItem>
                    <OrderCardHeading>Requested Date</OrderCardHeading>
                    <OrderCardText>
                      {localizeDate(currentOrderData.shippingDate)}
                    </OrderCardText>
                    <OrderCardText>
                      {currentOrderData.shippingTime}
                    </OrderCardText>
                  </FullScreenGridItem>
                  <FullScreenGridItem>
                    <OrderCardHeading>Purchase order #</OrderCardHeading>
                    <OrderCardText>
                      {currentOrderData.customerReference}
                    </OrderCardText>
                  </FullScreenGridItem>
                  <FullScreenGridItem>
                    <OrderCardHeading>Contract</OrderCardHeading>
                    <OrderCardText>
                      {currentOrderData.contractNumber}
                    </OrderCardText>
                  </FullScreenGridItem>
                  <FullScreenGridItem>
                    <OrderCardHeading>Shipping type</OrderCardHeading>
                    <OrderCardText>
                      {currentOrderData.shippingType}
                    </OrderCardText>
                  </FullScreenGridItem>
                  <FullScreenGridItem>
                    <OrderCardHeading>Order Placer</OrderCardHeading>
                    <OrderCardText>
                      {currentOrderData.orderPlacerName}
                    </OrderCardText>
                  </FullScreenGridItem>
                  {deliveries && (
                    <>
                      <FullScreenGridItem>
                        <OrderCardHeading>Vehicle</OrderCardHeading>
                        <OrderCardText>
                          {deliveries.modeOfTransport}
                        </OrderCardText>
                      </FullScreenGridItem>
                      <FullScreenGridItem>
                        <OrderCardHeading>Licence plate</OrderCardHeading>
                        <OrderCardText>
                          {deliveries.truckLicensePlate}
                        </OrderCardText>
                      </FullScreenGridItem>
                    </>
                  )}
                  <FullScreenGridItem>
                    <OrderCardHeading>Material & Volume</OrderCardHeading>
                    <OrderCardText>
                      {`${currentOrderData.lineItems[0].quantity} ${currentOrderData.lineItems[0].quantityUom} - ${currentOrderData.lineItems[0].materialDescription}`}
                    </OrderCardText>
                  </FullScreenGridItem>
                  <FullScreenGridItem>
                    <OrderCardHeading>Plant</OrderCardHeading>
                    <OrderCardText>
                      {currentOrderData.orgUnit.name}
                    </OrderCardText>
                    <OrderCardText>
                      {`${currentOrderData.orgUnit.street}, ${currentOrderData.orgUnit.city}, ${currentOrderData.orgUnit.postalCode}`}
                    </OrderCardText>
                  </FullScreenGridItem>
                  <FullScreenGridItem xs={12}>
                    <OrderCardHeading>Destination</OrderCardHeading>
                    <OrderCardText>
                      {currentOrderData.shippingAddress.siteName}
                    </OrderCardText>
                    <OrderCardText>
                      {`${currentOrderData.shippingAddress.street}, ${currentOrderData.shippingAddress.city}, ${currentOrderData.shippingAddress.postalCode}`}
                    </OrderCardText>
                  </FullScreenGridItem>
                </Grid>
              </Grid>
              {isDesktop && (
                <>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs={3}></Grid>
                </>
              )}
            </Grid>
            {deliveries && (
              <FullScreenDeliveriesContainer deliveries={deliveries} />
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} xl={3}>
          <Paper sx={{ padding: 4 }}>
            <Button
              variant="contained"
              fullWidth
              sx={{
                background: "white",
                color: "black",
                fontWeight: 700,
                padding: "10px 16px",
              }}
              startIcon={<DownloadIcon />}
            >
              Export
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FullscreenOrder;
