import { Box, Grid, Paper } from "@mui/material";
import { useSelector } from "react-redux";
import MobileOrderCard from "../Components/OrderList/MobileOrderCard/MobileOrderCard";
import OrderCardContainer from "../Components/OrderList/OrderCardContainer/OrderCardContainer";
import OrderFilters from "../Components/OrderList/OrderFilters/OrderFilters";
import OrderListOIButtons from "../Components/OrderList/OrderListOIButtons/OrderListOIButtons";
import OrderTable from "../Components/OrderList/OrderTable/OrderTable";
import { PageHeading } from "../Components/UI/Typography/SharedTypography";

const OrderList = () => {
  const isDesktop = useSelector((state) => state.general.isDesktop);

  return (
    <Box
      sx={{
        padding: { xs: "8px 16px", lgr: "15px 52px" },
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <PageHeading>ORDERS</PageHeading>
        <OrderListOIButtons />
      </Box>
      <Grid
        container
        position="relative"
        justifyContent="center"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item xs={12} lgr={8}>
          <Paper
            square={false}
            elevation={4}
            sx={isDesktop ? { padding: "10px 16px" } : { paddingTop: "5px" }}
          >
            {isDesktop ? (
              <>
                <OrderFilters />
                <OrderTable />
              </>
            ) : (
              <MobileOrderCard />
            )}
          </Paper>
        </Grid>
        {isDesktop && (
          <Grid item lgr={4}>
            <OrderCardContainer />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default OrderList;
