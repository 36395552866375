import { Button, Tooltip, Typography } from "@mui/material";

export const ButtonWithTooltip = ({ tooltipText = "", onClick, children }) => {
  return (
    <Tooltip title={tooltipText}>
      <Typography display="flex">
        <Button
          disabled={Boolean(tooltipText)}
          onClick={onClick}
          sx={{
            color: (theme) => theme.palette.text.primary,
            "&:hover": {
              background: (theme) => theme.palette.inputBackgroundColor.main,
            },
          }}
        >
          {children}
        </Button>
      </Typography>
    </Tooltip>
  );
};

export default ButtonWithTooltip;
