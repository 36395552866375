import { Box, styled } from "@mui/material";
import { CaptionHeading } from "../UI/Typography/SharedTypography";
import { copyright } from "constants/constants";
import { Link } from "react-router-dom";

const StyledLoginFooterLink = styled((props) => <Link {...props} />)(
  ({ theme }) => ({
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      textDecorationColor: theme.palette.text.secondary,
    },
  })
);

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        width: "100%",
        padding: 2,
        borderTop: "1px solid #628390",
        display: "flex",
        justifyContent: "center",
        columnGap: 4,
        "&>*": { color: "white" },
      }}
    >
      <CaptionHeading>{copyright}</CaptionHeading>
      <Box>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ display: "flex", columnGap: 1 }}>
            <StyledLoginFooterLink to="/tos">
              <CaptionHeading>Terms of Use</CaptionHeading>
            </StyledLoginFooterLink>
            <CaptionHeading>|</CaptionHeading>
            <StyledLoginFooterLink to="/privacy">
              <CaptionHeading>Privacy</CaptionHeading>
            </StyledLoginFooterLink>
          </Box>
        </Box>
        <StyledLoginFooterLink to="/oss">
          <CaptionHeading>Open Source Software Attribution</CaptionHeading>
        </StyledLoginFooterLink>
      </Box>
    </Box>
  );
};

export default Footer;
