import { styled, Typography } from "@mui/material";

export const PageHeading = styled((props) => <Typography {...props} />)(() => ({
  fontSize: "25px",
  fontWeight: 500,
  color: "white",
  textTransform: "uppercase",
}));

export const PageSubHeading = styled((props) => <Typography {...props} />)(
  () => ({
    fontSize: "20px",
    fontWeight: 700,
    color: "#54708c",
  })
);

export const MainColorHeading = styled((props) => (
  <Typography color="text.primary" variant="h6" {...props} />
))(() => ({
  fontSize: "18px",
  fontWeight: 700,
}));
