import {
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  Paper,
} from "@mui/material";

const PaymentStatusPopover = ({
  popoverWidth,
  setChoices,
  choices,
  statusArray,
}) => {
  const handleCheck = (e, option, index, options) => {
    const isAllStatus = option === "All Status";
    const isAllChoicesSelected = choices.length === options.length;

    if (e.target.checked) {
      if ((!isAllStatus && choices.length === 2) || isAllStatus) {
        setChoices(options);
      } else {
        setChoices((prev) => [...prev, options[index]]);
      }
    } else {
      if (isAllChoicesSelected && isAllStatus) return;
      if (isAllChoicesSelected && !isAllStatus) {
        setChoices([option]);
      } else {
        setChoices((prev) =>
          prev.filter((choice) => choice !== options[index])
        );
      }
    }
  };

  return (
    <Paper sx={{ width: popoverWidth, pt: 1, boxSizing: "border-box" }}>
      <List
        sx={{
          paddingY: 0,
          maxHeight: "250px",
          overflowY: "auto",
        }}
      >
        {statusArray.map((option, index, options) => {
          const isChecked = choices.includes(options[index]);
          return (
            <ListItem
              key={index}
              disablePadding
              divider={index < options.length - 1}
              sx={{
                alignItems: "flex-start",
                paddingX: 1,
                "&:hover": {
                  background: "rgba(0, 0, 0, 0.04);",
                },
              }}
            >
              <FormControlLabel
                sx={{ width: "100%" }}
                control={<Checkbox />}
                checked={isChecked}
                label={option}
                onChange={(e) => handleCheck(e, option, index, options)}
              />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
};

export default PaymentStatusPopover;
