import { Button, Toolbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { PageHeading } from "../Typography/SharedTypography";

const ToolBar = ({ text }) => {
  const navigate = useNavigate();

  return (
    <Toolbar sx={{ backgroundColor: "text.primary" }}>
      <Button
        onClick={() => navigate(-1)}
        sx={{
          color: "white",
          "&:hover": { textDecoration: "underline" },
          "& .MuiButton-startIcon>*:nth-of-type(1)": {
            fontSize: "30px",
            paddingBottom: "2px",
          },
        }}
        startIcon={<KeyboardArrowLeftIcon />}
      >
        <PageHeading>{text}</PageHeading>
      </Button>
    </Toolbar>
  );
};

export default ToolBar;
