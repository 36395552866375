import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Grid,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { PageHeading } from "../Components/UI/Typography/SharedTypography";
import WhiteOutlineButton from "../Components/UI/WhiteOutlineButton/WhiteOutlineButton";

const OverviewBoxStyled = styled((props) => <Box {...props} />)(() => ({
  padding: "24px",
  border: "1px solid rgba(255, 255, 255, .15)",
  borderRadius: "4px",
  backgroundColor: "rgba(0, 0, 0, .3)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  minHeight: "180px",
}));

const OverviewBoxHeadingStyled = styled((props) => <Typography {...props} />)(
  () => ({
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: "12px",
    letterSpacing: ".08em",
  })
);

const OverviewGridContainerStyled = styled((props) => (
  <Grid container spacing={1} {...props} />
))(() => ({}));

const OverviewButtonBoxStyled = styled((props) => (
  <Box textAlign="right" {...props} />
))(() => ({}));

const Overview = () => {
  return (
    <Box sx={{ padding: "48px 16px 16px", color: "white" }}>
      <Box
        sx={{
          maxWidth: "1280px",
          margin: "0 auto",
        }}
      >
        <PageHeading sx={{ marginBottom: 4 }}>Overview</PageHeading>
        <OverviewGridContainerStyled>
          <Grid item md={6} xs={12} sx={{ marginTop: 2 }}>
            <TextField
              variant="filled"
              fullWidth
              label="Enter customer number"
              InputLabelProps={{
                shrink: true,
                sx: {
                  color: "white",
                  transform: "none",
                  fontSize: "12px",
                  "&.Mui-focused": {
                    color: "white",
                  },
                },
              }}
              InputProps={{
                disableUnderline: false,
                endAdornment: (
                  <IconButton>
                    <SearchIcon sx={{ color: "white" }} />
                  </IconButton>
                ),
                sx: {
                  color: "white",
                  "&.Mui-focused": { background: "none" },
                },
              }}
              sx={{
                maxWidth: { md: "70%", xs: "100%" },
                "& .MuiInputBase-root": {
                  backgroundColor: "transparent",
                  border: "none",
                },
                "& .MuiInputBase-root:hover": {
                  background: "none",
                },
                "& .MuiFilledInput-root": {
                  borderBottom: "2px solid white",
                },
                "& .MuiFilledInput-root:after": {
                  border: "none",
                },
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography
              variant="body"
              sx={{
                display: "block",
                fontWeight: 500,
                marginBottom: 3,
              }}
            >
              Account number 33008274
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: 500 }}>
              ООО "АДАМАНТ СПб"
            </Typography>
          </Grid>
        </OverviewGridContainerStyled>
        <PageHeading sx={{ marginY: 4 }}>Finance</PageHeading>
        <OverviewGridContainerStyled>
          <Grid item md={6} xs={12}>
            <OverviewBoxStyled sx={{ minHeight: "120px" }}>
              <OverviewBoxHeadingStyled>
                Invoice by date
              </OverviewBoxHeadingStyled>
              <OverviewButtonBoxStyled sx={{ display: "flex" }}>
                <WhiteOutlineButton>PREV MONTH</WhiteOutlineButton>
                <WhiteOutlineButton>PREV 3 MONTHS</WhiteOutlineButton>
              </OverviewButtonBoxStyled>
            </OverviewBoxStyled>
          </Grid>
          <Grid item md={6} xs={12}>
            <OverviewBoxStyled sx={{ minHeight: "120px" }}>
              <OverviewBoxHeadingStyled>
                Invoices for last 30 days
              </OverviewBoxHeadingStyled>
              <Box sx={{ overflowX: "auto" }}>
                <Table
                  sx={{
                    "& .MuiTableCell-root": {
                      color: (theme) => theme.palette.text.secondary,
                      borderBottom: "none",
                    },
                  }}
                >
                  <TableBody>
                    <TableRow sx={{ cursor: "pointer" }}>
                      <TableCell>08/14/2024</TableCell>
                      <TableCell>6000205673</TableCell>
                      <TableCell>open</TableCell>
                      <TableCell>₽279,685.00</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
              <Box>
                <Button sx={{ textTransform: "none" }}>Show all</Button>
              </Box>
            </OverviewBoxStyled>
          </Grid>
        </OverviewGridContainerStyled>
        <PageHeading sx={{ marginY: 4 }}>Orders</PageHeading>
        <OverviewGridContainerStyled>
          <Grid item md={3} xs={12}>
            <OverviewBoxStyled>
              <OverviewBoxHeadingStyled>
                schedule new delivery
              </OverviewBoxHeadingStyled>
              <OverviewButtonBoxStyled>
                <Button variant="contained">schedule</Button>
              </OverviewButtonBoxStyled>
            </OverviewBoxStyled>
          </Grid>
          <Grid item md={3} xs={12}>
            <OverviewBoxStyled>
              <Box>
                <OverviewBoxHeadingStyled>
                  Generate PIN Code for pick up
                </OverviewBoxHeadingStyled>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ lineHeight: "14px" }}
                >
                  Save time and money. Click here to generate a PIN code a
                  driver can use to pick up your material.
                </Typography>
              </Box>
              <OverviewButtonBoxStyled>
                <Button variant="contained">Generate Pin Code</Button>
              </OverviewButtonBoxStyled>
            </OverviewBoxStyled>
          </Grid>
          <Grid item md={6} xs={12}>
            <OverviewBoxStyled>
              <OverviewBoxHeadingStyled>
                Orders by status
              </OverviewBoxHeadingStyled>
              <OverviewButtonBoxStyled>
                <WhiteOutlineButton>Upcoming</WhiteOutlineButton>
                <Button variant="contained">Today</Button>
              </OverviewButtonBoxStyled>
            </OverviewBoxStyled>
          </Grid>
        </OverviewGridContainerStyled>
      </Box>
    </Box>
  );
};

export default Overview;
