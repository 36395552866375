import { Box } from "@mui/material";
import AccountFilter from "../../UI/Filters/AccountFilter/AccountFilter";
import CustomerNumberFilter from "../../UI/Filters/CustomerNumberFilter/CustomerNumberFilter";
import HubDateRange from "../../UI/Filters/HubDateRange/HubDateRange";
import OrderFilter from "../../UI/Filters/OrderFilter/OrderFilter";
import SitesFilter from "../../UI/Filters/SitesFilter/SitesFilter";

const OrderFilters = () => {
  return (
    <Box display="flex" marginBottom={4} flexWrap="wrap" rowGap="8px">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <CustomerNumberFilter />
        {false && <AccountFilter />}
        <OrderFilter />
      </Box>
      <Box sx={{ display: "flex", columnGap: "16px" }}>
        <HubDateRange />
        <SitesFilter />
      </Box>
    </Box>
  );
};

export default OrderFilters;
