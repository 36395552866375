import InfoIcon from "@mui/icons-material/Info";
import { Box, styled } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import DeliveryOITableRow from "../DeliveryOITableRow/DeliveryOITableRow";

const TableCellStyled = styled(TableCell)(() => ({
  fontSize: "12px",
  fontWeight: 400,
  color: "rgb(84, 112, 140)",
}));

const DeliveryOITable = ({ isCollect }) => {
  const tableRows = useSelector((state) => state.deliveryOI.tableRows);

  return (
    <TableContainer component={Paper} sx={{ mb: 4 }}>
      <Table size="small" sx={{ minWidth: 600 }} aria-label="delivery table">
        <TableHead
          sx={{
            backgroundColor: "rgb(245, 246, 247)",
          }}
        >
          <TableRow>
            <TableCellStyled>#</TableCellStyled>
            <TableCellStyled>Date</TableCellStyled>
            <TableCellStyled>Type</TableCellStyled>
            <TableCellStyled>Load</TableCellStyled>
            <TableCellStyled>
              <Box display="flex" alignItems="center">
                {isCollect ? "Haulier Info" : "Special instructions"}
                <Tooltip
                  title={isCollect ? "Vehicle Info" : "Driver instructions"}
                >
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </Box>
            </TableCellStyled>
            <TableCellStyled>
              <Box display="flex" alignItems="center">
                PO#
                <Tooltip title="Purchase Order Number">
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </Box>
            </TableCellStyled>
            <TableCellStyled></TableCellStyled>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows.map((order, index, array) => (
            <DeliveryOITableRow
              key={order.orderId}
              order={order}
              index={index}
              array={array}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DeliveryOITable;
