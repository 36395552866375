import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import I18NextHttpBackend from "i18next-http-backend";

import translationEN from "./en/en.json";
import translationRU from "./ru/ru.json";

const resources = {
  en: {
    translation: translationEN,
  },
  ru: {
    translation: translationRU,
  },
};

i18n
  .use(initReactI18next)
  .use(I18NextHttpBackend)
  .init({
    resources,
    fallbackLng: "ru",
    lng: "en",
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
    // backend: {
    //   loadPath:
    //     "http://localhost:5544/api/translations/web-zakaz/hub/{{lng}}/file/",
    // },
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
  });

export default i18n;
