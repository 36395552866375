import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import { Box, Typography } from "@mui/material";
import PdfLink from "Components/UI/PdfLink/PdfLink";
import { dateComparator, localizeDate } from "helpers/helpers";
import { OrderTableText } from "../../../../Typography/OrderList";

export const InvoiceTableColumns = [
  {
    field: "creationDate",
    headerName: "Date",
    sortComparator: dateComparator,
    renderCell: (params) => (
      <Box>
        <OrderTableText sx={{ fontWeight: 500 }}>
          {localizeDate(params.value)}
        </OrderTableText>
      </Box>
    ),
    flex: 1,
  },
  {
    field: "invoiceNumber",
    headerName: "Number",
    renderCell: (params) => <PdfLink>{params.value}</PdfLink>,
    flex: 1.5,
    sortable: false,
  },
  {
    field: "type",
    headerName: "Type",
    renderCell: (params) => <OrderTableText>{params.value}</OrderTableText>,
    flex: 1,
    sortable: false,
  },
  {
    field: "invoiceNetValue",
    headerName: "Net",
    renderCell: (params) => <OrderTableText>{params.value}</OrderTableText>,
    flex: 1,
    sortable: false,
  },
  {
    field: "invoiceGrossValue",
    headerName: "Gross",
    renderCell: (params) => <OrderTableText>{params.value}</OrderTableText>,
    flex: 1,
    sortable: false,
  },
  {
    field: "status",
    headerName: "Status",
    renderCell: (params) => (
      <Typography
        sx={{
          alignSelf: "flex-start",
          display: "flex",
          fontSize: "14px",
          color: "#018444",
          backgroundColor: "#0184441A",
          padding: 1,
          borderRadius: "4px",
          alignItems: "center",
          columnGap: 0.5,
          textTransform: "capitalize",
          boxSizing: "border-box",
          maxHeight: "24px",
          "&>svg": {
            fontSize: "14px",
          },
        }}
      >
        <HourglassBottomIcon />
        {params.value}
      </Typography>
    ),
    sortable: false,
    minWidth: 100,
    flex: 1,
  },
];
