import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { Box, IconButton, TextField } from "@mui/material";
import { useState } from "react";
import { useGetTableData } from "hooks/useGetTableData";
import { useLazyFilterByOrderQuery } from "../../../../redux/api/orderListApiSlice";
import NumberFilterSelect from "./NumberFilterSelect";

const NumberFilter = () => {
  const { refetch, query, isInvoices } = useGetTableData();

  const [orderNumber, setOrderNumber] = useState("");

  const handleInputChange = (e) => setOrderNumber(e.target.value);
  const clearInput = () => setOrderNumber("");

  const [filterByOrder] = useLazyFilterByOrderQuery(query);

  const handleFilterByOrder = () => {
    if (orderNumber === "") return;
    filterByOrder({ customerId: query.customerId, orderNumber });
  };

  const clearOrderFilter = () => {
    refetch();
    clearInput();
  };

  const [selectValue, setSelectValue] = useState(
    isInvoices ? "Invoice" : "Order"
  );
  const handleSelectChange = (e) => setSelectValue(e.target.value);

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        backgroundColor: theme.palette.inputBackgroundColor.main,
        border: `1px solid ${theme.palette.inputBorderColor.main}`,
        borderRadius: "4px",
        "&:hover": {
          background: "#e7e7e7",
        },
      })}
    >
      <TextField
        label={`Search by ${selectValue} Number`}
        value={orderNumber}
        onChange={handleInputChange}
        variant="filled"
        onKeyDown={(e) => e.key === "Enter" && handleFilterByOrder()}
        sx={{
          width: "100%",
          "& .MuiInputBase-root": {
            background: "none",
            border: "none",
          },
        }}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <IconButton onClick={handleFilterByOrder}>
              <SearchIcon />
            </IconButton>
          ),
          disableUnderline: true,
          sx: {
            background: "none",
            borderRadius: 0,
            "&:hover": {
              background: "none",
            },
          },
        }}
      />
      {isInvoices && (
        <NumberFilterSelect
          selectValue={selectValue}
          handleSelectChange={handleSelectChange}
        />
      )}
      <Box
        sx={(theme) => ({
          display: "flex",
          borderLeft: `1px solid ${theme.palette.inputBorderColor.main}`,
        })}
      >
        <IconButton
          onClick={clearOrderFilter}
          sx={{
            "&:hover": {
              background: "none",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default NumberFilter;
