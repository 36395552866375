import Paper from "@mui/material/Paper";
import { keyframes } from "@mui/system";
import { useSelector } from "react-redux";
import OrderCard from "../OrderCard/OrderCard";
import NoDataOverlay from "../../UI/NoDataOverlay/NoDataOverlay";
import { useGetOrderListQuery } from "../../../redux/api/orderListApiSlice";

const OrderCardContainer = () => {
  const query = useSelector((state) => state.orderList.queryParams);
  const { data } = useGetOrderListQuery(query, {
    skip: !query.customerId,
  });
  const isCardOpen = useSelector((state) => state.orderList.isCardOpen);
  const currentOrderId = useSelector((state) => state.orderList.orderId);
  const currentOrderData = data?.results.length && data.results[currentOrderId];

  const slideCard = keyframes`from {
            right: -100%;
        }
        to {
            right: 0;
        }
        `;

  return (
    <Paper
      elevation={4}
      sx={(theme) => ({
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        position: "sticky",
        top: "10px",
        overflowY: "auto",
        minHeight: "570px",
        [theme.breakpoints.down("lgr")]: {
          display: !isCardOpen && "none",
          position: "fixed",
          top: "200px",
          right: "10px",
          minWidth: "300px",
          width: "40%",
          height: "auto",
          animation: `${slideCard} .2s`,
          zIndex: "6",
        },
      })}
    >
      {currentOrderData && isCardOpen ? (
        <OrderCard currentOrderData={currentOrderData} />
      ) : (
        <NoDataOverlay />
      )}
    </Paper>
  );
};

export default OrderCardContainer;
