import { Grid, IconButton, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { PageHeading } from "../UI/Typography/SharedTypography";
import { OverviewGridContainerStyled } from "./Shared";

const OverviewGrid = () => {
  return (
    <>
      <PageHeading sx={{ marginBottom: 4 }}>Overview</PageHeading>
      <OverviewGridContainerStyled>
        <Grid item md={6} xs={12} sx={{ marginTop: 2 }}>
          <TextField
            variant="filled"
            fullWidth
            label="Enter customer number"
            InputLabelProps={{
              shrink: true,
              sx: {
                color: "white",
                transform: "none",
                fontSize: "12px",
                "&.Mui-focused": {
                  color: "white",
                },
              },
            }}
            InputProps={{
              disableUnderline: false,
              endAdornment: (
                <IconButton>
                  <SearchIcon sx={{ color: "white" }} />
                </IconButton>
              ),
              sx: {
                color: "white",
                "&.Mui-focused": { background: "none" },
              },
            }}
            sx={{
              maxWidth: { md: "70%", xs: "100%" },
              "& .MuiInputBase-root": {
                backgroundColor: "transparent",
                border: "none",
              },
              "& .MuiInputBase-root:hover": {
                background: "none",
              },
              "& .MuiFilledInput-root": {
                borderBottom: "2px solid white",
              },
              "& .MuiFilledInput-root:after": {
                border: "none",
              },
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography
            variant="body"
            sx={{
              display: "block",
              fontWeight: 500,
              marginBottom: 3,
            }}
          >
            Account number 33008274
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 500 }}>
            ООО "АДАМАНТ СПб"
          </Typography>
        </Grid>
      </OverviewGridContainerStyled>
    </>
  );
};

export default OverviewGrid;
