import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { formatOnlyNumbers } from "../../../../helpers/helpers";
import ButtonWithTooltip from "../../../UI/ButtonWithTooltip/ButtonWithTooltip";
import { useFormContext } from "react-hook-form";

const IncrementInput = ({
  truckCapacity: {
    capacity,
    minCapacity,
    maxCapacity,
    quantityChangeIncrement,
    capacityUom,
  },
}) => {
  const [amount, setAmount] = useState(35);
  const [increment, setIncrement] = useState(1);
  const [minimalCapacity, setMinimalCapacity] = useState(5);
  const [maximumCapacity, setMaximumCapacity] = useState(50);
  const unitOfMeasure = capacityUom || "t";

  const handleAmount = (amount) => {
    if (amount < minimalCapacity) return setAmount(minimalCapacity);
    if (amount > maximumCapacity) return setAmount(maximumCapacity);
    return setAmount(amount);
  };

  const subtractAmount = () => handleAmount(amount - increment);
  const addAmount = () => handleAmount(amount + increment);
  const handleChange = (e) => {
    setAmount(formatOnlyNumbers(e.target.value));
  };

  const overMin = `To request a quantity below the minimum (${minCapacity} ${unitOfMeasure}), please contact the order office.`;
  const overMax = `To request an amount above the maximum (${maxCapacity} ${unitOfMeasure}), please contact dispatch.`;

  useEffect(() => {
    setAmount(capacity);
  }, [capacity]);

  useEffect(() => {
    setMinimalCapacity(minCapacity);
    setMaximumCapacity(maxCapacity);
    setIncrement(quantityChangeIncrement);
  }, [minCapacity, maxCapacity, quantityChangeIncrement]);

  const { setValue } = useFormContext();

  useEffect(() => {
    setValue("truckCapacity", { capacity: amount });
  }, [setValue, amount]);

  return (
    <TextField
      id="oi-delivery-form-quantity"
      label={`Quantity ${unitOfMeasure}`}
      variant="filled"
      value={amount}
      onChange={handleChange}
      onBlur={() => handleAmount(amount)}
      inputProps={{
        maxLength: 4,
        style: {
          textAlign: "center",
          paddingLeft: 0,
          paddingRight: 0,
        },
      }}
      InputProps={{
        disableUnderline: true,
        style: {
          padding: 0,
          alignItems: "stretch",
        },
        startAdornment: (
          <ButtonWithTooltip
            tooltipText={amount === minCapacity && overMin}
            onClick={subtractAmount}
          >
            <KeyboardArrowLeftIcon />
          </ButtonWithTooltip>
        ),
        endAdornment: (
          <ButtonWithTooltip
            tooltipText={amount === maxCapacity && overMax}
            onClick={addAmount}
          >
            <KeyboardArrowRightIcon />
          </ButtonWithTooltip>
        ),
      }}
      InputLabelProps={{
        style: {
          top: "8%",
          left: "50%",
          transform: "translate(-50%, 0)",
          fontSize: "12px",
          textTransform: "none",
        },
      }}
    />
  );
};

export default IncrementInput;
