import { useState } from "react";
import OrderTableBody from "../OrderTableBody/OrderTableBody";
import OrderTablePagination from "../OrderTablePagination/OrderTablePagination";
import { useActions } from "../../../hooks/useActions";
import { useSelector } from "react-redux";

const OrderTable = () => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const { setQueryParams } = useActions();
  const query = useSelector((state) => state.orderList.queryParams);

  const handlePage = (pageModel) => {
    setPaginationModel((prev) => ({
      ...prev,
      ...pageModel,
      page: prev.page === pageModel.page ? 0 : pageModel.page,
    }));
    setQueryParams({
      offset: pageModel.page * query.limit,
      limit: pageModel.pageSize,
    });
  };

  return (
    <>
      <OrderTablePagination handlePage={handlePage} />
      <OrderTableBody
        paginationModel={paginationModel}
        setPaginationModel={handlePage}
      />
    </>
  );
};

export default OrderTable;
