import { createSlice } from "@reduxjs/toolkit";

const generalSlice = createSlice({
  name: "general",
  initialState: {
    isDesktop: window.innerWidth > 900,
  },
  reducers: {
    setIsDesktop: (state, action) => {
      state.isDesktop = action.payload;
    },
  },
});

export const { actions } = generalSlice;

export default generalSlice.reducer;
