import { TextField } from "@mui/material";
import { useState } from "react";

const EmailInput = () => {
  const [isError, setIsError] = useState(false);

  const isValidEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const validateEmail = (e) =>
    isValidEmail.test(e.target.value) || e.target.value === ""
      ? setIsError(false)
      : setIsError(true);

  return (
    <TextField
      fullWidth
      type="email"
      variant="filled"
      label="Email CC"
      onChange={validateEmail}
      error={isError}
    />
  );
};

export default EmailInput;
