import { Box, Tooltip } from "@mui/material";
import {
  deliverTruckBulk,
  pickupTruckBulk,
} from "assets/img/statuses/statusImages";
import { dateComparator, localizeDate, localizeTime } from "helpers/helpers";
import StatusIcon from "../../../../StatusIcon/StatusIcon";
import {
  OrderTableCaption,
  OrderTableText,
} from "../../../../Typography/OrderList";

export const OrderTableColumns = [
  {
    field: "shippingDate",
    headerName: "Requested Date",
    sortComparator: dateComparator,
    renderCell: (params) => (
      <Box>
        <OrderTableText sx={{ fontWeight: 500 }}>
          {localizeDate(params.value.date)}
        </OrderTableText>
        <OrderTableCaption>
          {`${localizeTime(params.value.startTime)} - ${localizeTime(
            params.value.endTime
          )}`}
        </OrderTableCaption>
      </Box>
    ),
    flex: 0,
  },
  {
    field: "po-order",
    headerName: "PO#/ORDER#",
    // maxWidth: 200,
    renderCell: (params) => (
      <Box>
        <OrderTableText>{params.value.customerReference}</OrderTableText>
        <OrderTableCaption>{params.value.orderNumber}</OrderTableCaption>
      </Box>
    ),
    flex: 1,
    sortable: false,
  },
  {
    field: "destination",
    headerName: "Destination",
    renderCell: (params) => (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <OrderTableText>{params.value.siteName}</OrderTableText>
        <OrderTableCaption>{params.value.street}</OrderTableCaption>
      </Box>
    ),
    flex: 1,
    sortable: false,
  },
  {
    field: "material",
    headerName: "Material",
    renderCell: (params) => <OrderTableText>{params.value}</OrderTableText>,
    flex: 1,
    sortable: false,
  },
  {
    field: "ordered-delivered",
    headerName: "Ordered/Delivered",
    renderCell: ({ value }) => {
      const isShippingTypeCollect = value.shippingType === "collect";
      const shippingType = isShippingTypeCollect
        ? pickupTruckBulk
        : deliverTruckBulk;

      const tooltipTitle = `${
        isShippingTypeCollect ? "Pick-up" : "Delivery"
      } by bulk carrier`;

      return (
        <Box sx={{ display: "flex", columnGap: "5px" }}>
          <OrderTableText>{value.quantity} t</OrderTableText>
          <Tooltip title={tooltipTitle}>
            <img src={shippingType} alt={shippingType} width="24px" />
          </Tooltip>
        </Box>
      );
    },
    flex: 0,
    sortable: false,
  },
  {
    field: "status",
    headerName: "Status",
    renderCell: (params) => <StatusIcon status={params.value} />,
    flex: 0,
    maxWidth: 60,
    sortable: false,
  },
];
