import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { PageSubHeading } from "../UI/Typography/SharedTypography";
import FullscreenDeliveriesDesktop from "./FullscreenDeliveriesDesktop";
import FullscreenDeliveriesMobile from "./FullscreenDeliveriesMobile";

const FullScreenDeliveriesContainer = ({ deliveries }) => {
  const isDesktop = useSelector((state) => state.general.isDesktop);

  return (
    <Box>
      <PageSubHeading sx={{ marginY: 3 }}>Deliveries</PageSubHeading>

      {isDesktop ? (
        <FullscreenDeliveriesDesktop deliveries={deliveries} />
      ) : (
        <FullscreenDeliveriesMobile deliveries={deliveries} />
      )}
    </Box>
  );
};

export default FullScreenDeliveriesContainer;
