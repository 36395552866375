import { getDefaultCustomerFromLS } from "helpers/localstoragehelper";
import { useGetCreditWidgetDataQuery } from "../../../redux/api/creditWidgetApiSlice";
import CreditWidgetInstance from "./CreditWidgetInstance";

const CreditWidget = () => {
  const localStorageCustomer = getDefaultCustomerFromLS();

  const { data, isSuccess } = useGetCreditWidgetDataQuery(
    localStorageCustomer?.customerId,
    {
      skip: !localStorageCustomer?.customerId,
    }
  );

  return (
    isSuccess &&
    data.map((creditData, index) => (
      <CreditWidgetInstance
        key={index}
        creditData={creditData}
        payer={localStorageCustomer?.customerName}
      />
    ))
  );
};

export default CreditWidget;
