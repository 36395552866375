import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { PageHeading } from "../UI/Typography/SharedTypography";
import {
  OverviewBoxHeadingStyled,
  OverviewBoxStyled,
  OverviewButtonBoxStyled,
  OverviewGridContainerStyled,
} from "./Shared";
import WhiteOutlineButton from "../UI/WhiteOutlineButton/WhiteOutlineButton";
import CreditWidget from "./CreditWidget/CreditWidget";

const FinanceGrid = () => {
  return (
    <>
      <PageHeading sx={{ marginY: 4 }}>Finance</PageHeading>
      <CreditWidget />
      <OverviewGridContainerStyled>
        <Grid item md={6} xs={12}>
          <OverviewBoxStyled sx={{ minHeight: "120px" }}>
            <OverviewBoxHeadingStyled>Invoice by date</OverviewBoxHeadingStyled>
            <OverviewButtonBoxStyled sx={{ display: "flex" }}>
              <WhiteOutlineButton>PREV MONTH</WhiteOutlineButton>
              <WhiteOutlineButton>PREV 3 MONTHS</WhiteOutlineButton>
            </OverviewButtonBoxStyled>
          </OverviewBoxStyled>
        </Grid>
        <Grid item md={6} xs={12}>
          <OverviewBoxStyled sx={{ minHeight: "120px" }}>
            <OverviewBoxHeadingStyled>
              Invoices for last 30 days
            </OverviewBoxHeadingStyled>
            <Box sx={{ overflowX: "auto" }}>
              <Table
                sx={{
                  "& .MuiTableCell-root": {
                    color: (theme) => theme.palette.text.secondary,
                    borderBottom: "none",
                  },
                }}
              >
                <TableBody>
                  <TableRow sx={{ cursor: "pointer" }}>
                    <TableCell>08/14/2024</TableCell>
                    <TableCell>6000205673</TableCell>
                    <TableCell>open</TableCell>
                    <TableCell>₽279,685.00</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
            <Box>
              <Button sx={{ textTransform: "none" }}>Show all</Button>
            </Box>
          </OverviewBoxStyled>
        </Grid>
      </OverviewGridContainerStyled>
    </>
  );
};

export default FinanceGrid;
