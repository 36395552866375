import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import WhiteOutlineButton from "../UI/WhiteOutlineButton/WhiteOutlineButton";
import { useIsComponentEnabled } from "hooks/useIsComponentEnabled";
import { CaptionHeading } from "../UI/Typography/SharedTypography";
import InfoIcon from "@mui/icons-material/Info";

const OrderListOIButtons = () => {
  const { getIsComponentEnabled } = useIsComponentEnabled();
  const isComponentEnabled = getIsComponentEnabled("OrderListOIButtons");

  if (!isComponentEnabled)
    return (
      <Box sx={{ display: "flex", columnGap: 1 }}>
        <CaptionHeading sx={{ display: "contents" }}>
          <InfoIcon />
        </CaptionHeading>
        <CaptionHeading>
          You are not allowed to order for this account number.
        </CaptionHeading>
      </Box>
    );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginBottom: "5px",
      }}
    >
      <Link to="/bulkCementDelivery">
        <WhiteOutlineButton>SCHEDULE NEW DELIVERY</WhiteOutlineButton>
      </Link>
      <Link to="/bulkCementCollection">
        <WhiteOutlineButton>GENERATE PIN CODE FOR PICK UP</WhiteOutlineButton>
      </Link>
      <Typography
        sx={{
          fontSize: "12px",
          color: "#99afb7",
        }}
      >
        Save time and money. Click here to generate a PIN code a driver can use
        to pick up your material.
      </Typography>
    </Box>
  );
};

export default OrderListOIButtons;
