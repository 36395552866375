import { CardContent, Grid, Paper } from "@mui/material";
import FullScreenGridItem from "Components/FullscreenOrder/FullScreenGridItem";
import PdfLink from "Components/UI/PdfLink/PdfLink";
import { localizeDate } from "helpers/helpers";
import { OrderCardHeading, OrderCardText } from "../UI/Typography/OrderCard";

const DeliveryItem = ({ delivery, isShown = false }) => {
  return (
    <Paper
      sx={{
        marginY: 1,
        border: (theme) => `1px solid ${theme.palette.inputBorderColor.main}`,
        background: (theme) => theme.palette.inputBackgroundColor.main,
        width: "100%",
        maskImage: isShown
          ? "none"
          : "linear-gradient(rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%)",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)",
        borderRadius: "8px",
        borderImage: "initial",
        overflow: "hidden",
      }}
    >
      <CardContent sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <FullScreenGridItem>
            <OrderCardHeading>Ticket</OrderCardHeading>
            <PdfLink>{delivery.deliveryNumber}</PdfLink>
          </FullScreenGridItem>
          <FullScreenGridItem>
            <OrderCardHeading>PO# / Order#</OrderCardHeading>
            <OrderCardText>{localizeDate(delivery.shippingDate)}</OrderCardText>
          </FullScreenGridItem>
          <FullScreenGridItem sm={12}>
            <OrderCardHeading>Material</OrderCardHeading>
            <OrderCardText>{`${delivery.materialDescription}`}</OrderCardText>
          </FullScreenGridItem>
          <FullScreenGridItem sm={12}>
            <OrderCardHeading>Quantity</OrderCardHeading>
            <OrderCardText>{`${delivery.quantity} ${delivery.quantityUom}`}</OrderCardText>
          </FullScreenGridItem>
          <FullScreenGridItem>
            <OrderCardHeading>Truck</OrderCardHeading>
            <OrderCardText>{delivery.truckLicensePlate}</OrderCardText>
          </FullScreenGridItem>
          <FullScreenGridItem>
            <OrderCardHeading>Pick up</OrderCardHeading>
            <OrderCardText>{delivery.plantName}</OrderCardText>
          </FullScreenGridItem>
        </Grid>
      </CardContent>
    </Paper>
  );
};

export default DeliveryItem;
