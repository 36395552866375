import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ContentTableBody from "./ContentTableBody/ContentTableBody";
import ContentTablePagination from "./ContentTablePagination";
import ContentTableSearchInfo from "./ContentTableSearchInfo";
import { calculatedPage } from "helpers/helpers";
import { useActions } from "hooks/useActions";

const ContentTable = () => {
  const query = useSelector((state) => state.orderList.queryParams);
  const { setQueryParams } = useActions();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: query.limit,
    page: calculatedPage(query.offset, query.limit),
  });

  const handlePagination = (pageModel) => {
    setQueryParams({
      offset: pageModel.page * query.limit,
      limit: pageModel.pageSize,
    });
    setPaginationModel({
      page: pageModel.page,
      pageSize: pageModel.pageSize,
    });
  };

  return (
    <>
      <ContentTablePagination
        handlePagination={handlePagination}
        pageSize={paginationModel.pageSize}
      />
      <ContentTableSearchInfo />
      <ContentTableBody
        paginationModel={paginationModel}
        handlePagination={handlePagination}
      />
    </>
  );
};

export default ContentTable;
