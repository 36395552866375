import { apiSlice } from "./apiSlice";
const ORDER_LIST = "order-items/";
const CUSTOMERS = "customers/";
const SITES = "sites/";

export const orderListApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCustomer: builder.query({
      query: (customerNumber = "") =>
        `${CUSTOMERS}?customerNumber=${customerNumber}`,
      transformResponse: (response) => response.results,
    }),
    getCustomers: builder.query({
      query: () => `${CUSTOMERS}`,
      transformResponse: (response) => response.results,
    }),
    getSites: builder.query({
      query: (customerId) => `${SITES}?customerId=${customerId}`,
      transformResponse: (response) => response.results,
    }),
    getOrderList: builder.query({
      query: (arg) => ({
        url: ORDER_LIST,
        params: { ...arg },
      }),
      providesTags: ["Orders"],
    }),
    filterByOrder: builder.query({
      query: ({ customerId, orderNumber }) =>
        `${ORDER_LIST}?customerId=${customerId}&orderNumber=${orderNumber}`,
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            apiSlice.util.updateQueryData(
              "getOrderList",
              { ...getState().orderList.queryParams },
              () => data
            )
          );
        } catch (err) {
          console.log(err);
        }
      },
    }),
  }),
});

export const { useGetOrderListQuery } = orderListApiSlice;
export const { useGetCustomerQuery } = orderListApiSlice;
export const { useGetSitesQuery } = orderListApiSlice;
export const { useGetCustomersQuery } = orderListApiSlice;

export const { useLazyFilterByOrderQuery } = orderListApiSlice;
