import { getUserId } from "helpers/localstoragehelper";
import { useContext, useEffect } from "react";
import AuthContext from "../context/AuthProvider";
import { useGetUserQuery } from "../redux/api/userApiSlice";

const useAuth = () => {
  const { data, isSuccess } = useGetUserQuery(getUserId());
  const { auth, setAuth } = useContext(AuthContext);

  useEffect(() => {
    const userId = getUserId();
    const getAuth = (data) => {
      setAuth({
        name: data.name,
        username: data.username,
        email: data.eMail,
        roles: data.hasRoles,
        userId: data?.user_id || userId,
      });
    };
    isSuccess && getAuth(data);
  }, [data, setAuth, isSuccess]);

  return { auth, setAuth };
};

export default useAuth;
