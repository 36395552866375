import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const now = moment().startOf("day").toISOString();
const weekAfterNow = moment().add(1, "week").endOf("day").toISOString();

const initialState = {
  isCardOpen: false,
  orderId: undefined,
  queryParams: {
    customerId: "",
    siteId: "",
    shippingDate: now,
    endDate: weekAfterNow,
    sortedBy: "-shippingDate",
    limit: 10,
    offset: 0,
  },
};

const orderListSlice = createSlice({
  name: "orderList",
  initialState,
  reducers: {
    setQueryParams: (state, { payload }) => {
      state.queryParams = {
        ...state.queryParams,
        ...payload,
        offset: payload.offset || 0,
      };
    },
    setIsCardOpen: (state, action) => {
      state.isCardOpen = action.payload;
    },
    setOrderId: (state, action) => {
      state.orderId = action.payload;
    },
  },
});

export const { actions } = orderListSlice;
export default orderListSlice.reducer;
