export const rowsPerPage = [10, 50, 100];
export const allSites = [
  {
    id: 0,
    title: "All Sites",
  },
  {
    id: 1,
    title: 'ООО "АДАМАНТ СПБ"',
    caption: "Глухоозерское шоссе, д. 12 лит.А - г. Санкт-Петербург",
    stringId: "ID: 33008274",
  },
  {
    id: 2,
    title: 'ООО "АДАМАНТ СПБ"',
    caption: "Глухоозерское шоссе, д. 12 лит.А - г. Санкт-Петербург",
    stringId: "ID: 33010973",
  },
  {
    id: 3,
    title: 'ООО "АДАМАНТ СПБ"',
    caption: "УЛ МИНЕРАЛЬНАЯ 13 - г. Санкт-Петербург",
    stringId: "ID: 33010974",
  },
  {
    id: 4,
    title: 'ООО "АДАМАНТ СПБ"',
    caption: "Глухоозерское шоссе, д. 12 лит.А - г. Санкт-Петербург",
    stringId: "ID: 33015739",
  },
];

export const accounts = [
  {
    id: 1,
    title: 'АО "Тулачермет"',
    caption: "Account 17060646",
  },
  {
    id: 2,
    title: 'ООО "КНАУФ ГИПС"',
    caption: "Account 33000189",
  },
];

export const timeOptions = [
  { label24h: "00:00" },
  { label24h: "00:30" },
  { label24h: "01:00" },
  { label24h: "01:30" },
  { label24h: "02:00" },
  { label24h: "02:30" },
  { label24h: "03:00" },
  { label24h: "03:30" },
  { label24h: "04:00" },
  { label24h: "04:30" },
  { label24h: "05:00" },
  { label24h: "05:30" },
  { label24h: "06:00" },
  { label24h: "06:30" },
  { label24h: "07:00" },
  { label24h: "07:30" },
  { label24h: "08:00" },
  { label24h: "08:30" },
  { label24h: "09:00" },
  { label24h: "09:30" },
  { label24h: "10:00" },
  { label24h: "10:30" },
  { label24h: "11:00" },
  { label24h: "11:30" },
  { label24h: "12:00" },
  { label24h: "12:30" },
  { label24h: "13:00" },
  { label24h: "13:30" },
  { label24h: "14:00" },
  { label24h: "14:30" },
  { label24h: "15:00" },
  { label24h: "15:30" },
  { label24h: "16:00" },
  { label24h: "16:30" },
  { label24h: "17:00" },
  { label24h: "17:30" },
  { label24h: "18:00" },
  { label24h: "18:30" },
  { label24h: "19:00" },
  { label24h: "19:30" },
  { label24h: "20:00" },
  { label24h: "20:30" },
  { label24h: "21:00" },
  { label24h: "21:30" },
  { label24h: "22:00" },
  { label24h: "22:30" },
  { label24h: "23:00" },
  { label24h: "23:30" },
  { label24h: "23:59" },
];

export const testOrder = {
  requestId: "6309c476-48ca-45d4-998a-13d6a4a6f3af",
  type: "createOrder",
  createdOn: "2024-08-15T08:25:21.6850341Z",
  createdBy: "6f7151f2-bb29-4961-bab5-9f61647aadd2",
  status: "notValidated",
  orgUnitId: "RU22",
  businessLine: "CEM",
  customerNumber: "33017599",
  siteNumber: "33017617",
  countryId: "RU",
  requestData: {
    countryId: "RU",
    shippingType: "deliver",
    quantity: 35,
    quantityUom: "t",
    quantityType: "truckCapacity",
    contact: {
      mainPhone: "+74872456777",
      backupPhone: "",
      shipToEmail: "",
      confirmationSapEmailEnabled: false,
      fullName: "Cloned user 612810",
      carbonCopyEmail: "",
    },
    customerNumber: "33017599",
    deliveryDate: "2024-08-16",
    deliveryTime: {
      earliest: "14:00",
      latest: "23:59",
    },
    customerReference: "8",
    siteNumber: "33017617",
    shippingAddress: {
      siteName: 'АО "Тулачермет"',
      street: "ул.Пржевальского 2",
      postalCode: "300016",
      city: "г.Тула",
      state: "71",
      country: "RU",
    },
    plantNumber: "RU22",
    plant: {
      plantName: "ХЦ Рус в п. Новогуровский",
      street: "",
      postalCode: "301382",
      city: "р.п. Новогуровский",
      state: "71",
      country: "RU",
    },
    materialNumber: "434693",
    material: {
      materialDescription: "ЦЕМ II / А-Ш 42,5Н",
      materialEnteredNumber: "105571",
      materialDescriptionLong: "",
    },
    additionalDriverInfo: {},
    country: "RU",
    businessLineCode: "CEM",
    customerIdPreffix: "eu",
    isSendingConfirmationEmailUnChecked: false,
    haulerInfo: {
      truckLicensePlate: "",
      trailerLicensePlate: "",
      driverName: "",
      driverPhoneNumber: "",
    },
    contractNumber: "3309006473",
    contractItemPosNumber: "000020",
  },
  isSendingConfirmationEmailUnChecked: false,
};

export const testOptions = [
  {
    siteNumber: "33010974",
    siteName: 'ООО "АДАМАНТ СПб"',
    street: "ул. Минеральная, 13",
    postalCode: "195197",
    city: "г.Санкт-Петербург",
    state: "78",
    countryId: "RU",
    orgUnitId: "RU10",
    customerNumber: "33008274",
    customerReference: "21",
    zkPartner: "",
    driverInstructions: "",
    contact: {
      mainPhone: "UNDEFINED",
      backupPhone: "UNDEFINED",
    },
    timeZone: "2",
    truckCapacity: {
      capacity: 22,
      maxCapacity: 45,
      minCapacity: 10,
      shortLoadThreshold: 0,
      quantityChangeIncrement: 1,
      numberOfDecimals: 0,
      isRoundToChangeIncrement: false,
    },
    contractValidityPeriods: {
      contractValidityStartDate: "2024-09-20",
      contractValidityEndDate: "2030-06-01",
    },
    materials: [
      {
        invalid: false,
        deactivationReason: "",
        canDisableEmailSending: true,
        shippingType: "deliver",
        materialEnteredNumber: "107675",
        materialNumber: "107675",
        materialDescription: "ЦЕМ II/А-Ш 42,5Н",
        contractNumber: "22",
        contractItemPositionNumber: "10",
        businessLine: "CEM",
        quantityType: "1",
        customerMaterialDescription: "",
        materialEnteredDescription: "ЦЕМ II/А-Ш 42,5Н",
        contractItemDescription: "2",
        poNumber: "2",
      },
    ],
    defaultDeliveryWindow: {
      defaultEarliestLoadTime: "14:00",
      defaultLatestLoadTime: "23:59",
    },
    businessDays: {
      cutOffTime: "2024-09-13T13:00:00",
      daysBeforeCutOffTime: [
        "2024-09-20",
        "2024-09-21",
        "2024-09-22",
        "2024-09-23",
        "2024-09-24",
        "2024-09-25",
        "2024-09-26",
        "2024-09-27",
      ],
      daysAfterCutOffTime: [
        "2024-09-20",
        "2024-09-21",
        "2024-09-22",
        "2024-09-23",
        "2024-09-24",
        "2024-09-25",
        "2024-09-26",
        "2024-09-27",
      ],
    },
  },
  {
    siteNumber: "33010973",
    siteName: 'ООО "АДАМАНТ СПб"',
    street: "Глухоозерское шоссе, д. 12 лит.А",
    postalCode: "192019",
    city: "г.Санкт-Петербург",
    state: "78",
    countryId: "RU",
    orgUnitId: "RU10",
    customerNumber: "33008274",
    customerReference: "21",
    zkPartner: "",
    driverInstructions: "",
    contact: {
      mainPhone: "UNDEFINED",
      backupPhone: "UNDEFINED",
    },
    timeZone: "?",
    truckCapacity: {
      capacity: 22,
      maxCapacity: 45,
      minCapacity: 10,
      shortLoadThreshold: 0,
      quantityChangeIncrement: 1,
      numberOfDecimals: 0,
      isRoundToChangeIncrement: false,
    },
    contractValidityPeriods: {
      contractValidityStartDate: "2024-07-23",
      contractValidityEndDate: "2024-09-30",
    },
    materials: [
      {
        invalid: false,
        deactivationReason: "",
        canDisableEmailSending: true,
        shippingType: "deliver",
        materialEnteredNumber: "107571",
        materialNumber: "107571",
        materialDescription: "ЦЕМ II / В-Ш 42,5Н",
        contractNumber: "3309006872",
        contractItemPositionNumber: "000010",
        businessLine: "CEM",
        quantityType: "truckCapacity",
        customerMaterialDescription: "",
        materialEnteredDescription: "ЦЕМ II / В-Ш 42,5Н",
        contractItemDescription: "ЦЕМ II / В-Ш 42,5Н",
        poNumber: "21",
      },
      {
        invalid: false,
        deactivationReason: "",
        canDisableEmailSending: true,
        shippingType: "deliver",
        materialEnteredNumber: "104125",
        materialNumber: "104125",
        materialDescription: "ЦЕМ I 42,5Н",
        contractNumber: "3309006872",
        contractItemPositionNumber: "000020",
        businessLine: "CEM",
        quantityType: "truckCapacity",
        customerMaterialDescription: "",
        materialEnteredDescription: "ЦЕМ I 42,5Н",
        contractItemDescription: "ЦЕМ I 42,5Н",
        poNumber: "21",
      },
      {
        invalid: false,
        deactivationReason: "",
        canDisableEmailSending: true,
        shippingType: "deliver",
        materialEnteredNumber: "434693",
        materialNumber: "434693",
        materialDescription: "ЦЕМ II/А-Ш 42,5Н ЖИ",
        contractNumber: "3309006872",
        contractItemPositionNumber: "000030",
        businessLine: "CEM",
        quantityType: "truckCapacity",
        customerMaterialDescription: "",
        materialEnteredDescription: "ЦЕМ II/А-Ш 42,5Н ЖИ",
        contractItemDescription: "ЦЕМ II/А-Ш 42,5Н ЖИ",
        poNumber: "21",
      },
      {
        invalid: false,
        deactivationReason: "",
        canDisableEmailSending: true,
        shippingType: "deliver",
        materialEnteredNumber: "113111",
        materialNumber: "113111",
        materialDescription: "Мука известняковая (доломитовая)",
        contractNumber: "3309006872",
        contractItemPositionNumber: "000040",
        businessLine: "CEM",
        quantityType: "truckCapacity",
        customerMaterialDescription: "",
        materialEnteredDescription: "Мука известняковая (доломитовая)",
        contractItemDescription: "Мука известняковая (доломитовая)",
        poNumber: "21",
      },
      {
        invalid: false,
        deactivationReason: "",
        canDisableEmailSending: true,
        shippingType: "deliver",
        materialEnteredNumber: "106629",
        materialNumber: "106629",
        materialDescription: "Порошок минеральный для бетона",
        contractNumber: "3309006872",
        contractItemPositionNumber: "000050",
        businessLine: "CEM",
        quantityType: "truckCapacity",
        customerMaterialDescription: "",
        materialEnteredDescription: "Порошок минеральный для бетона",
        contractItemDescription: "Порошок минеральный для бетона",
        poNumber: "21",
      },
    ],
    defaultDeliveryWindow: {
      defaultEarliestLoadTime: "14:00",
      defaultLatestLoadTime: "23:59",
    },
    businessDays: {
      cutOffTime: "2024-09-13T13:00:00",
      daysBeforeCutOffTime: [
        "2024-09-14",
        "2024-09-15",
        "2024-09-16",
        "2024-09-17",
        "2024-09-18",
        "2024-09-19",
        "2024-09-20",
        "2024-09-21",
        "2024-09-22",
        "2024-09-23",
        "2024-09-24",
        "2024-09-25",
        "2024-09-26",
        "2024-09-27",
      ],
      daysAfterCutOffTime: [
        "2024-09-17",
        "2024-09-18",
        "2024-09-19",
        "2024-09-20",
        "2024-09-21",
        "2024-09-22",
        "2024-09-23",
        "2024-09-24",
        "2024-09-25",
        "2024-09-26",
        "2024-09-27",
      ],
    },
  },
  {
    siteNumber: "33010974",
    siteName: 'ООО "АДАМАНТ СПб"',
    street: "ул. Минеральная, 13",
    postalCode: "195197",
    city: "г.Санкт-Петербург",
    state: "78",
    countryId: "RU",
    orgUnitId: "RU10",
    customerNumber: "33008274",
    customerReference: "21",
    zkPartner: "",
    driverInstructions: "",
    contact: {
      mainPhone: "UNDEFINED",
      backupPhone: "UNDEFINED",
    },
    timeZone: "?",
    truckCapacity: {
      capacity: 22,
      maxCapacity: 45,
      minCapacity: 10,
      shortLoadThreshold: 0,
      quantityChangeIncrement: 1,
      numberOfDecimals: 0,
      isRoundToChangeIncrement: false,
    },
    contractValidityPeriods: {
      contractValidityStartDate: "2024-07-23",
      contractValidityEndDate: "2024-09-19",
    },
    materials: [
      {
        invalid: false,
        deactivationReason: "",
        canDisableEmailSending: true,
        shippingType: "deliver",
        materialEnteredNumber: "107571",
        materialNumber: "107571",
        materialDescription: "ЦЕМ II / В-Ш 42,5Н",
        contractNumber: "3309006873",
        contractItemPositionNumber: "000010",
        businessLine: "CEM",
        quantityType: "?",
        customerMaterialDescription: "",
        materialEnteredDescription: "ЦЕМ II / В-Ш 42,5Н",
        contractItemDescription: "?",
        poNumber: "?",
      },
    ],
    defaultDeliveryWindow: {
      defaultEarliestLoadTime: "14:00",
      defaultLatestLoadTime: "23:59",
    },
    businessDays: {
      cutOffTime: "2024-09-13T13:00:00",
      daysBeforeCutOffTime: [
        "2024-09-14",
        "2024-09-15",
        "2024-09-16",
        "2024-09-17",
        "2024-09-18",
        "2024-09-19",
      ],
      daysAfterCutOffTime: ["2024-09-17", "2024-09-18", "2024-09-19"],
    },
  },
];

const sorted = Object.groupBy(
  testOptions,
  ({ siteName, siteNumber, street, city }) =>
    `${siteName}-${siteNumber}-${street}-${city}`
);

export const spreadObj = [];
for (const property in sorted) {
  if (sorted[property].length > 1) {
    spreadObj.push({
      siteName: sorted[property][0].siteName,
      siteNumber: sorted[property][0].siteNumber,
      street: sorted[property][0].street,
      city: sorted[property][0].city,
      postalCode: sorted[property][0].postalCode,
      businessDays: {
        ...sorted[property][0].businessDays,
        ...sorted[property][1].businessDays,
        daysAfterCutOffTime: [
          ...sorted[property][0].businessDays.daysAfterCutOffTime,
          ...sorted[property][1].businessDays.daysAfterCutOffTime,
        ].flat(),
        daysBeforeCutOffTime: [
          ...sorted[property][0].businessDays.daysBeforeCutOffTime,
          ...sorted[property][1].businessDays.daysBeforeCutOffTime,
        ].flat(),
      },
      options: sorted[property].map((option) => option),
    });
    continue;
  }
  spreadObj.push(sorted[property][0]);
}
