import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import ListIcon from "@mui/icons-material/List";
import {
  Alert,
  Box,
  Button,
  ButtonBase,
  ButtonGroup,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { getUserId } from "helpers/localstoragehelper";
import { useActions } from "hooks/useActions";
import { useState } from "react";
import { useGetUserQuery } from "../../redux/api/userApiSlice";
import PhoneInput from "../UI/PhoneInput/PhoneInput";
import { PageSubHeading } from "../UI/Typography/SharedTypography";
import DeliveryOICollectionAddress from "./DeliveryOICollectionAddress";
import DestinationDropdown from "./DeliveryOIForm/DestinationDropdown";
import DeliverIntakeTable from "./DeliveryOITable";

const DeliveryOIOverview = ({ isCollect }) => {
  const { addAnotherDelivery } = useActions();
  const { data } = useGetUserQuery(getUserId());

  const [isCopyPreviousOrderVisible, setIsCopyPreviousOrderVisible] =
    useState(true);

  const handleIsAlertVisible = () =>
    setIsCopyPreviousOrderVisible((prev) => !prev);
  const handleAddAnotherDelivery = () => addAnotherDelivery();

  return (
    <>
      <PageSubHeading marginBottom={2}>
        {isCollect ? "Collect address" : "Delivery address"}
      </PageSubHeading>
      {isCollect ? <DeliveryOICollectionAddress /> : <DestinationDropdown />}
      {isCopyPreviousOrderVisible && (
        <Alert
          icon={false}
          action={
            <ButtonGroup
              variant="text"
              sx={{
                ".MuiButtonGroup-grouped": {
                  border: 0,
                  mx: 1,
                  lineHeight: "0",
                },
              }}
            >
              <Button size="small" startIcon={<DoneIcon />}>
                <span>YES</span>
              </Button>
              <Button size="small" startIcon={<ListIcon />}>
                SHOW
              </Button>
              <ButtonBase onClick={handleIsAlertVisible}>
                <CloseIcon sx={{ fontSize: "20px" }} />
              </ButtonBase>
            </ButtonGroup>
          }
          severity="info"
          sx={{ mt: 4 }}
          onClose={() => {}}
        >
          Copy last order?
        </Alert>
      )}
      <Stack marginTop={4}>
        <PageSubHeading marginBottom={2}>
          Material and Delivery Time
        </PageSubHeading>
        <DeliverIntakeTable isCollect={isCollect} />
        <Paper
          onClick={handleAddAnotherDelivery}
          sx={{
            p: 1,
            backgroundColor: "rgba(1, 125, 209, .02)",
            "&:hover": { boxShadow: 4 },
            cursor: "pointer",
          }}
        >
          <Button
            startIcon={<AddIcon />}
            size="small"
            sx={{ textTransform: "capitalize" }}
          >
            Add more
          </Button>
        </Paper>
      </Stack>
      <Box marginTop={2}>
        <PageSubHeading marginBottom={1}>Contact Details</PageSubHeading>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            columnGap: 2,
          }}
        >
          <TextField
            id="contact-fullname"
            label="Full Name"
            defaultValue={data?.name}
            helperText="Required"
            variant="filled"
            FormHelperTextProps={{
              sx: { ml: 0 },
            }}
            sx={{ flexBasis: "45%" }}
          />
          <PhoneInput label="Main Phone" visible />
          {!isCollect && <PhoneInput label="Backup Phone" isHideable />}
        </Box>
      </Box>
    </>
  );
};

export default DeliveryOIOverview;
