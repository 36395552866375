import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import { localizeCurrency } from "helpers/helpers";
import { OrderCardHeading, OrderCardText } from "../UI/Typography/OrderCard";

const InvoiceCostSummary = ({ currentOrderData }) => {
  const boxFlex = { display: "flex", justifyContent: "space-between" };
  return (
    <>
      <OrderCardText>Cost summary</OrderCardText>
      <Divider sx={{ marginY: 1 }} />
      <OrderCardHeading variant="subtitle1">MATERIALS</OrderCardHeading>
      <Box sx={boxFlex}>
        <OrderCardText>
          {currentOrderData?.lineItems[0]?.materialDescription}
        </OrderCardText>
        <OrderCardText>
          {localizeCurrency(
            currentOrderData.invoiceNetValue,
            currentOrderData.invoiceCurrency
          )}
        </OrderCardText>
      </Box>
      <OrderCardHeading variant="subtitle1">TAX</OrderCardHeading>
      <Box sx={boxFlex}>
        <OrderCardText>Sales tax</OrderCardText>
        <OrderCardText>
          {localizeCurrency(
            currentOrderData.invoiceTaxValue,
            currentOrderData.invoiceCurrency
          )}
        </OrderCardText>
      </Box>
      <Box
        sx={{
          ...boxFlex,
          padding: 1,
          marginY: 1,
          marginX: -1,
          background: "rgb(235, 243, 252);",
          "&>p": { fontWeight: 700 },
        }}
      >
        <OrderCardText>Total</OrderCardText>
        <OrderCardText>
          {localizeCurrency(
            currentOrderData.invoiceGrossValue,
            currentOrderData.invoiceCurrency
          )}
        </OrderCardText>
      </Box>
    </>
  );
};

export default InvoiceCostSummary;
