import {
  Box,
  Button,
  FormControl,
  Link,
  Paper,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HCLogo } from "../assets/img/images";
import HubSnackbar from "../Components/UI/HubSnackbar/HubSnackbar";
import { useLoginMutation } from "../redux/api/authApiSlice";

const StyledTextnput = styled((props) => <TextField {...props} />)(() => ({
  ".Mui-error	": {
    border: "1px solid red",
    borderRadius: "4px",
  },
}));

const Login = () => {
  const navigate = useNavigate();

  const [login, { isLoading }] = useLoginMutation();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleUsername = ({ target: { value } }) => setUsername(value);
  const handlePassword = ({ target: { value } }) => setPassword(value);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userData = await login({ username, password }).unwrap();
      localStorage.setItem("token", userData.token);
      localStorage.setItem("userId", userData.userId);
      localStorage.setItem("customerNumber", "33008274");
      navigate("/orders");
    } catch (error) {
      setErrorMsg(error?.data?.error || "Unhandled error");
      setIsError(true);
    }
  };

  useEffect(() => {
    const errorTimeOut = setTimeout(() => {
      setIsError(false);
    }, 6000);

    return () => {
      clearTimeout(errorTimeOut);
    };
  }, [isError]);

  useEffect(() => {
    if (username === "" || password === "") setIsButtonDisabled(true);
    if (username && password) setIsButtonDisabled(false);
    if (isLoading) setIsButtonDisabled(true);
  }, [username, password, isLoading]);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", flexGrow: 1 }}>
      <HubSnackbar msg={errorMsg} isOpen={isError} />
      <Paper
        square={false}
        sx={{
          padding: "48px",
          display: "flex",
          flexDirection: "column",
          minHeight: "500px",
          alignSelf: "center",
          width: "450px",
          boxSizing: "border-box",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 5,
          }}
        >
          <Box sx={{ width: "200px" }}>
            <img src={HCLogo} alt="branding logo" />
          </Box>
        </Box>
        <form onSubmit={handleSubmit} style={{ display: "contents" }}>
          <FormControl
            sx={{
              flex: "1 1 0%",
            }}
          >
            {" "}
            <Typography variant="h6" marginBottom={3}>
              Log in
            </Typography>
            <StyledTextnput
              value={username}
              name="zakaz-login"
              onChange={handleUsername}
              error={isError}
              hiddenLabel
              variant="filled"
              placeholder="login"
              sx={{ marginBottom: 1 }}
            />
            <StyledTextnput
              value={password}
              name="zakaz-password"
              onChange={handlePassword}
              error={isError}
              hiddenLabel
              variant="filled"
              placeholder="password"
              type="password"
              sx={{ marginBottom: 2 }}
            />
            <Button
              type="submit"
              variant="contained"
              disabled={isButtonDisabled}
            >
              Login
            </Button>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "12px",
                marginTop: "auto",
              }}
            >
              <Typography>Not registered?</Typography>
              <Link>Request access</Link>
            </Box>
          </FormControl>
        </form>
      </Paper>
    </Box>
  );
};

export default Login;
