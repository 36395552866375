import { Paper, Typography } from "@mui/material";

const NotFound = () => (
  <Paper sx={{ marginTop: 2 }}>
    <Typography>
      This page is either not found or under construction. Come back later.
    </Typography>
  </Paper>
);

export default NotFound;
