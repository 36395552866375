import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { rowsPerPage } from "constants/constants";
import { useActions } from "hooks/useActions";
import { useDetailsCard } from "hooks/useDetailsCard";
import { useGetTableData } from "hooks/useGetTableData";
import { useEffect } from "react";
import { InvoiceTableColumns } from "./Columns/InvoiceTableColumns";
import { OrderTableColumns } from "./Columns/OrderTableColumns";
import { OrderTableStyles } from "./ContentTableBodyStyles";

const ContentTableBody = ({ paginationModel, handlePagination }) => {
  const apiRef = useGridApiRef();
  const { isFetching, data, handleItem, isInvoices } = useGetTableData();
  const columns = isInvoices ? InvoiceTableColumns : OrderTableColumns;

  const handlePageSize = ({ pageSize, page }) => {
    handlePagination({
      page: paginationModel.pageSize !== pageSize ? 0 : page,
      pageSize: pageSize,
    });
  };
  const { setOrderId } = useActions();
  const { openCard, closeCard } = useDetailsCard();

  const openOrderCard = ({ id }) => {
    setOrderId(id - 1);
    openCard();
  };

  useEffect(() => {
    !isFetching &&
      apiRef.current.autosizeColumns({
        includeHeaders: true,
        includeOutliers: true,
      });
  }, [isFetching, apiRef]);

  useEffect(() => {
    closeCard();
  }, [closeCard]);

  const rows =
    data && data.results.map((item, index) => handleItem(item, index));

  return (
    <DataGrid
      autosizeOptions={{
        includeOutliers: true,
        includeHeaders: true,
      }}
      autosizeOnMount
      autoHeight
      checkboxSelection
      disableRowSelectionOnClick
      disableColumnMenu
      disableColumnResize
      rowCount={data?.count || -1}
      paginationMode="server"
      columnHeaderHeight={40}
      rows={rows || []}
      loading={isFetching}
      apiRef={apiRef}
      columns={columns}
      getRowHeight={() => "auto"}
      pageSizeOptions={rowsPerPage}
      onRowClick={openOrderCard}
      sortingOrder={["desc", "asc", null]}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 10 },
        },
      }}
      paginationModel={paginationModel}
      onPaginationModelChange={handlePageSize}
      sx={OrderTableStyles}
    />
  );
};

export default ContentTableBody;
