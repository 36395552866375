import { Box, Grid, Paper, Typography } from "@mui/material";
import DeliveryOIAdditionalInfo from "Components/DeliveryOI/DeliveryOIAdditionalInfo.jsx";
import DeliveryOIConfirmation from "Components/DeliveryOI/DeliveryOIConfirmation.jsx";
import DeliveryOIOverview from "Components/DeliveryOI/DeliveryOIOverview.jsx";
import DeliveryOITabsForm from "Components/DeliveryOI/DeliveryOITabsForm.jsx";
import ToolBar from "Components/UI/ToolBar/ToolBar.jsx";
import { useActions } from "hooks/useActions.jsx";
import { useIsComponentEnabled } from "hooks/useIsComponentEnabled.jsx";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useGetDeliveryOptionsQuery } from "../redux/api/deliveryApiSlice.js";

const OrderIntake = ({ isCollect = false }) => {
  const { setIsConfirmation, setIsTabsFormOpen } = useActions();

  const isConfirmation = useSelector(
    (state) => state.deliveryOI.isConfirmation
  );

  const {
    isFetching: isFetchingDeliveries,
    isSuccess: isSuccessDeliveries,
    data,
  } = useGetDeliveryOptionsQuery(isCollect ? "collect" : "deliver", {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    setIsConfirmation(false);
    setIsTabsFormOpen(true);
  }, [setIsConfirmation, setIsTabsFormOpen]);

  const { getIsComponentEnabled } = useIsComponentEnabled();
  const isComponentEnabled = getIsComponentEnabled("OrderIntake");

  if (!isComponentEnabled) return <Navigate to="/overview" replace={true} />;

  if (isFetchingDeliveries)
    return <Typography sx={{ color: "white" }}>Loading!</Typography>;

  if (!data?.length)
    return <Typography sx={{ color: "white" }}>No options!</Typography>;

  return (
    <>
      <ToolBar
        text={
          isCollect ? "GENERATE PIN CODE FOR PICK UP" : "SCHEDULE NEW DELIVERY"
        }
      />
      <Box
        sx={{
          p: { md: 4 },
          display: "flex",
          justifyContent: "center",
        }}
      >
        {!isFetchingDeliveries && isSuccessDeliveries && (
          <Grid container maxWidth="1280px">
            <Grid item xs={12} md={8} sx={{ marginBottom: { xs: 4, md: 0 } }}>
              <Paper square={false} sx={{ padding: 4 }}>
                {isConfirmation ? (
                  <DeliveryOIConfirmation isCollect={isCollect} />
                ) : (
                  <DeliveryOIOverview isCollect={isCollect} />
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} sx={{ paddingLeft: { xs: "0", md: 2 } }}>
              <Paper square={false} sx={{ p: 4 }}>
                {isConfirmation ? (
                  <DeliveryOIAdditionalInfo isCollect={isCollect} />
                ) : (
                  <DeliveryOITabsForm isCollect={isCollect} />
                )}
              </Paper>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default OrderIntake;
