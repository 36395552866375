import { Box, Paper } from "@mui/material";
import LegalPageContent from "Components/LegalPage/LegalPageContent";
import ToolBar from "../Components/UI/ToolBar/ToolBar";

const LegalPage = ({ page = "terms" }) => {
  return (
    <>
      <ToolBar text="Back" />
      <Box sx={{ background: "white", height: "100%" }}>
        <Paper sx={{ maxWidth: "1200px", margin: "0 auto", padding: "30px" }}>
          <LegalPageContent page={page} />
        </Paper>
      </Box>
    </>
  );
};

export default LegalPage;
