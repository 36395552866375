import { Box, Grid, Paper, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import DeliveryOIAdditionalInfo from "../Components/DeliveryOI/DeliveryOIAdditionalInfo/DeliveryOIAdditionalInfo.jsx";
import DeliveryOIConfirmation from "../Components/DeliveryOI/DeliveryOIConfirmation/DeliveryOIConfirmation.jsx";
import DeliveryOIOverview from "../Components/DeliveryOI/DeliveryOIOverview/DeliveryOIOverview.jsx";
import DeliveryOITabsForm from "../Components/DeliveryOI/DeliveryOITabsForm/DeliveryOITabsForm.jsx";
import { useActions } from "../hooks/useActions.jsx";
import { useGetDeliveryOptionsQuery } from "../redux/api/deliveryApiSlice.js";
import ToolBar from "../Components/UI/ToolBar/ToolBar.jsx";

const OrderIntake = ({ isCollect = false }) => {
  const { setIsConfirmation, setIsTabsFormOpen } = useActions();

  const isConfirmation = useSelector(
    (state) => state.deliveryOI.isConfirmation
  );

  const {
    isLoading: isLoadingDeliveries,
    isSuccess: isSuccessDeliveries,
    data,
  } = useGetDeliveryOptionsQuery(isCollect ? "collect" : "deliver", {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    setIsConfirmation(false);
    setIsTabsFormOpen(true);
  }, [setIsConfirmation, setIsTabsFormOpen]);

  if (data?.results?.length === 0)
    return <Typography sx={{ color: "white" }}>No collect options!</Typography>;

  return (
    <>
      <ToolBar
        text={
          isCollect ? "GENERATE PIN CODE FOR PICK UP" : "SCHEDULE NEW DELIVERY"
        }
      />
      <Box
        sx={{
          p: { md: 4 },
          display: "flex",
          justifyContent: "center",
        }}
      >
        {isLoadingDeliveries && (
          <Typography sx={{ color: "white" }}>Loading!</Typography>
        )}
        {isSuccessDeliveries && (
          <Grid container maxWidth="1280px">
            <Grid item xs={12} md={8} sx={{ marginBottom: { xs: 4, md: 0 } }}>
              <Paper square={false} sx={{ padding: 4 }}>
                {isConfirmation ? (
                  <DeliveryOIConfirmation isCollect={isCollect} />
                ) : (
                  <DeliveryOIOverview isCollect={isCollect} />
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} sx={{ paddingLeft: { xs: "0", md: 2 } }}>
              <Paper square={false} sx={{ p: 4 }}>
                {isConfirmation ? (
                  <DeliveryOIAdditionalInfo isCollect={isCollect} />
                ) : (
                  <DeliveryOITabsForm isCollect={isCollect} />
                )}
              </Paper>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default OrderIntake;
