import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

const DriverPhoneInput = ({ value }) => {
  const [driverPhone, setDriverPhone] = useState("");
  const [isValid, setIsValid] = useState(false);

  const handleChange = (newValue) => {
    setIsValid(!matchIsValidTel(newValue));
    setDriverPhone(newValue);
  };

  const { setValue } = useFormContext();

  useEffect(() => {
    setValue("haulerInfo.driverPhoneNumber", driverPhone || "");
  }, [setValue, driverPhone]);

  useEffect(() => {
    setDriverPhone(value);
  }, [value]);

  return (
    <MuiTelInput
      fullWidth
      error={isValid}
      value={driverPhone}
      onChange={handleChange}
      id="hauler-phone"
      label="Driver phone number"
      variant="filled"
      defaultCountry="RU"
      InputProps={{ disableUnderline: true }}
      sx={{ marginBottom: 2 }}
    />
  );
};

export default DriverPhoneInput;
