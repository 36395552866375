import { Typography } from "@mui/material";
import {
  last14Days,
  last30Days,
  last3rdMonth,
  lastMonthEnd,
  lastMonthStart,
  todayEnd,
} from "constants/dates";
import { formattedDateRange } from "helpers/helpers";
import StyledCalendarToggleButton from "./StyledCalendarToggleButton";

const SelectionInvoices = ({
  setLast14Days,
  setLast30Days,
  setLastMonth,
  setLast3Months,
}) => {
  return (
    <>
      <StyledCalendarToggleButton
        value="last 14 days"
        aria-label="last 14 days"
        onClick={setLast14Days}
      >
        <Typography>Last 14 days</Typography>
        <Typography>{formattedDateRange(last14Days, todayEnd)}</Typography>
      </StyledCalendarToggleButton>
      <StyledCalendarToggleButton
        value="last 30 days"
        aria-label="last 30 days"
        onClick={setLast30Days}
      >
        <Typography>Last 30 days</Typography>
        <Typography>{formattedDateRange(last30Days, todayEnd)}</Typography>
      </StyledCalendarToggleButton>
      <StyledCalendarToggleButton
        value="last month"
        aria-label="last month"
        onClick={setLastMonth}
      >
        <Typography>Last month</Typography>
        <Typography>
          {formattedDateRange(lastMonthStart, lastMonthEnd)}
        </Typography>
      </StyledCalendarToggleButton>
      <StyledCalendarToggleButton
        value="last 3 months"
        aria-label="last 3 months"
        onClick={setLast3Months}
      >
        <Typography>Last 3 months</Typography>
        <Typography>
          {formattedDateRange(last3rdMonth, lastMonthEnd)}
        </Typography>
      </StyledCalendarToggleButton>
    </>
  );
};

export default SelectionInvoices;
