import { Box, ToggleButtonGroup, Typography } from "@mui/material";
import {
  last14Days,
  last30Days,
  last3rdMonth,
  lastMonthEnd,
  lastMonthStart,
  today,
  todayEnd,
  weekFromToday,
} from "constants/dates";
import { formattedDateRange } from "helpers/helpers";
import { useGetTableData } from "hooks/useGetTableData";
import SelectionInvoices from "./SelectionInvoices";
import SelectionOrders from "./SelectionOrders";

const QuickSelection = ({
  setTextDateRange,
  setChosenPeriod,
  setActiveDate,
  setPresetPeriod,
  presetPeriod,
}) => {
  const { isInvoices } = useGetTableData();

  const setToday = () => {
    setTextDateRange(formattedDateRange(today));
    setChosenPeriod([today, todayEnd]);
    setActiveDate(today);
  };

  const setOneWeek = () => {
    setTextDateRange(formattedDateRange(today, weekFromToday));
    setChosenPeriod([today, weekFromToday]);
    setActiveDate(today);
  };

  const setLastMonth = () => {
    setTextDateRange(formattedDateRange(lastMonthStart, lastMonthEnd));
    setChosenPeriod([lastMonthStart, lastMonthEnd]);
    setActiveDate(lastMonthStart);
  };

  const setLast14Days = () => {
    setTextDateRange(formattedDateRange(last14Days, todayEnd));
    setChosenPeriod([last14Days, todayEnd]);
    setActiveDate(last14Days);
  };

  const setLast3Months = () => {
    setTextDateRange(formattedDateRange(last3rdMonth, lastMonthEnd));
    setChosenPeriod([last3rdMonth, lastMonthEnd]);
    setActiveDate(last3rdMonth);
  };

  const setLast30Days = () => {
    setTextDateRange(formattedDateRange(last30Days, todayEnd));
    setChosenPeriod([last30Days, todayEnd]);
    setActiveDate(last30Days);
  };

  const handlePresetDate = (_, presetPeriod) => setPresetPeriod(presetPeriod);

  return (
    <Box marginLeft="16px">
      <Box
        sx={{
          paddingLeft: "16px",
          marginBottom: "16px",
          height: "44px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography color="#99afb7">Quick Selection</Typography>
      </Box>
      <ToggleButtonGroup
        value={presetPeriod}
        exclusive
        onChange={handlePresetDate}
        aria-label="preset period"
        sx={{ flexDirection: "column" }}
      >
        {isInvoices ? (
          <SelectionInvoices
            setLast14Days={setLast14Days}
            setLast30Days={setLast30Days}
            setLastMonth={setLastMonth}
            setLast3Months={setLast3Months}
          />
        ) : (
          <SelectionOrders
            setLastMonth={setLastMonth}
            setOneWeek={setOneWeek}
            setToday={setToday}
          />
        )}
      </ToggleButtonGroup>
    </Box>
  );
};

export default QuickSelection;
