import Button from "@mui/material/Button";
import { styled } from "@mui/material";

const WhiteOutlineButton = styled((props) => (
  <Button variant="outlined" size="medium" {...props} />
))(() => ({
  margin: "5px",
  color: "white",
  borderColor: "#99afb7",
  ":hover": {
    borderColor: "white",
  },
}));

export default WhiteOutlineButton;
