import DateRangeIcon from "@mui/icons-material/DateRange";
import {
  Box,
  Button,
  InputAdornment,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import Popover from "@mui/material/Popover";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useActions } from "../../../../hooks/useActions";
import "./HubDateRange.css";

const StyledCalendarToggleButton = styled((props) => (
  <ToggleButton {...props} />
))(() => ({
  color: "white",
  textTransform: "none",
  flexDirection: "column",
  alignItems: "start",
  padding: "8px 16px",
  "&:hover": {
    backgroundColor: "rgba(231, 231, 231, .1)",
  },
  "&.Mui-selected": {
    backgroundColor: "rgba(231, 231, 231, .2)",
    color: "white",
  },
  "&.Mui-selected:hover": {
    backgroundColor: "rgba(231, 231, 231, .1)",
  },
}));

const today = new Date().setHours(0, 0, 0, 0, 0);
const todayEnd = new Date().setHours(23, 59, 59, 999, 999);
const weekFromToday = new Date(todayEnd).setDate(
  new Date(todayEnd).getDate() + 7
);
const lastMonthStart = new Date(today);
lastMonthStart.setDate(1);
lastMonthStart.setMonth(new Date().getMonth() - 1);
const lastMonthEnd = new Date(todayEnd).setDate(0);

const formattedDateRange = (...dates) => {
  const dateOne = dates[0] && new Date(dates[0]);
  const dateTwo = dates[1] && new Date(dates[1]);
  if (dates.length === 1) {
    return new Intl.DateTimeFormat().format(dateOne);
  }
  if (dates.length > 1 && dateOne.getDate() === dateTwo.getDate()) {
    return new Intl.DateTimeFormat().format(dateOne);
  }
  return `${new Intl.DateTimeFormat().format(
    dateOne
  )}-${new Intl.DateTimeFormat().format(dateTwo)}`;
};

const HubDateRange = () => {
  const { setQueryParams } = useActions();

  const [anchorEl, setAnchorEl] = useState(null);

  const openPopover = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [textDateRange, setTextDateRange] = useState(
    formattedDateRange(today, weekFromToday)
  );

  const [presetPeriod, setPresetPeriod] = useState("left");
  const [chosenPeriod, setChosenPeriod] = useState([today, weekFromToday]);
  const [activeDate, setActiveDate] = useState(null);

  const setToday = () => {
    setTextDateRange(formattedDateRange(today));
    setChosenPeriod([today, todayEnd]);
    setActiveDate(today);
  };

  const setOneWeek = () => {
    setTextDateRange(formattedDateRange(today, weekFromToday));
    setChosenPeriod([today, weekFromToday]);
    setActiveDate(today);
  };

  const setLastMonth = () => {
    setTextDateRange(formattedDateRange(lastMonthStart, lastMonthEnd));
    setChosenPeriod([lastMonthStart, lastMonthEnd]);
    setActiveDate(lastMonthStart);
  };

  const setDate = (e) => {
    setTextDateRange(formattedDateRange(e[0], e[1]));
    setChosenPeriod([e[0], e[1]]);
  };

  const handlePresetDate = (_, presetPeriod) => setPresetPeriod(presetPeriod);
  const cancelPresetDate = () => {
    setActiveDate(null);
    setPresetPeriod(null);
  };

  const sendDate = () => {
    handleClose();
    setQueryParams({
      shippingDate: new Date(chosenPeriod[0]).toISOString(),
      endDate: new Date(chosenPeriod[1]).toISOString(),
    });
  };
  return (
    <Box>
      <Button sx={{ display: "contents" }}>
        <TextField
          onMouseDown={(e) => e.preventDefault()}
          onClick={openPopover}
          variant="filled"
          label="Date"
          value={textDateRange}
          sx={{
            input: { cursor: "pointer" },
            ".MuiInputBase-root": {
              cursor: "pointer",
            },
          }}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <DateRangeIcon />
              </InputAdornment>
            ),
          }}
        />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ backgroundColor: "#001E2A", color: "white" }}>
          <Box sx={{ display: "flex" }}>
            <Calendar
              selectRange
              onActiveStartDateChange={() => setActiveDate(null)}
              onChange={setDate}
              value={chosenPeriod}
              onClickDay={cancelPresetDate}
              activeStartDate={activeDate}
            />
            <Box marginLeft="16px">
              <Box
                sx={{
                  paddingLeft: "16px",
                  marginBottom: "16px",
                  height: "44px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography color="#99afb7">Quick Selection</Typography>
              </Box>
              <ToggleButtonGroup
                value={presetPeriod}
                exclusive
                onChange={handlePresetDate}
                aria-label="preset period"
                sx={{ flexDirection: "column" }}
              >
                <StyledCalendarToggleButton
                  value="week"
                  aria-label="one week"
                  onClick={setOneWeek}
                >
                  <Typography>One Week</Typography>
                  <Typography>{`${formattedDateRange(
                    today,
                    weekFromToday
                  )}`}</Typography>
                </StyledCalendarToggleButton>
                <StyledCalendarToggleButton
                  value="today"
                  aria-label="today"
                  onClick={setToday}
                >
                  <Typography>Today</Typography>
                  <Typography>{formattedDateRange(today)}</Typography>
                </StyledCalendarToggleButton>
                <StyledCalendarToggleButton
                  value="last month"
                  aria-label="last month"
                  onClick={setLastMonth}
                >
                  <Typography>Last month</Typography>
                  <Typography>
                    {formattedDateRange(lastMonthStart, lastMonthEnd)}
                  </Typography>
                </StyledCalendarToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={sendDate} sx={{ margin: "16px" }}>
              Apply
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default HubDateRange;
