import { Box, styled } from "@mui/system";

const StickyButtonBox = styled((props) => <Box {...props} />)(
    () => ({
        position: 'sticky',
        bottom: '30px',
        zIndex: 2,
        padding: '16px',
        backgroundColor: 'rgb(251, 251, 252)',
        borderTop: '1px solid rgb(234, 237, 240)'
    }),
);

export default StickyButtonBox;