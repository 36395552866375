import { Box, Button, Divider } from "@mui/material";
import { useState } from "react";
import { OrderCardHeading, OrderCardText } from "../UI/Typography/OrderCard";
import DeliveryItem from "./DeliveryItem";

const InvoiceDeliveryItems = ({ currentOrderData }) => {
  const [isViewAll, setIsViewAll] = useState(false);

  const handleViewAll = () => {
    setIsViewAll((prev) => !prev);
  };

  if (!currentOrderData.lineItems.length) return null;

  return (
    <>
      <Divider sx={{ marginY: 1 }} />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <OrderCardHeading variant="subtitle">DELIVERY ITEMS</OrderCardHeading>
          <OrderCardText>
            {currentOrderData.lineItems.length} deliveries
          </OrderCardText>
        </Box>
        <Button onClick={handleViewAll}>View All</Button>
      </Box>
      <Box>
        {isViewAll ? (
          currentOrderData.lineItems.map((delivery, index) => (
            <DeliveryItem key={index} delivery={delivery} isShown={isViewAll} />
          ))
        ) : (
          <DeliveryItem delivery={currentOrderData.lineItems[0]} />
        )}
      </Box>
    </>
  );
};

export default InvoiceDeliveryItems;
