import GroupIcon from "@mui/icons-material/Group";
import LogoutIcon from "@mui/icons-material/Logout";
import TranslateIcon from "@mui/icons-material/Translate";
import { Divider, ListItemIcon } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { copyright } from "constants/constants";
import { getUserId } from "helpers/localstoragehelper";
import useAuth from "hooks/useAuth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLogoutMutation } from "../../redux/api/authApiSlice";
import { useGetUserQuery } from "../../redux/api/userApiSlice";
import NavLinkStyled from "../UI/NavLinkStyled/NavLinkStyled";
import LegalMenu from "./LegalMenu";

const UserMenu = () => {
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const { data } = useGetUserQuery(getUserId());
  const [logout] = useLogoutMutation();

  const handleLogOut = () => {
    handleCloseUserMenu();
    logout();
    setAuth();
    navigate("/login");
  };

  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  return (
    <Box sx={{ flexGrow: 0, paddingRight: { xs: 0, lg: "10px" } }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar alt={data?.name} src="/static/images/avatar/2.jpg" />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        disableScrollLock
        keepMounted
        anchorEl={anchorElUser}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ mt: "45px" }}
      >
        <MenuItem
          sx={{
            padding: "18px 24px",
            textAlign: "center",
            flexDirection: "column",
          }}
        >
          <Avatar
            alt={data?.name}
            src="/static/images/avatar/2.jpg"
            sx={{ margin: "0 auto" }}
          />
          <Typography>{data?.name}</Typography>
          <Typography>{data?.eMail}</Typography>
        </MenuItem>
        <Divider />
        <MenuItem key="English">
          <ListItemIcon>
            <TranslateIcon fontSize="small" />
          </ListItemIcon>
          <Typography textAlign="center">English</Typography>
        </MenuItem>
        <Divider />
        <LegalMenu closeMenu={handleCloseUserMenu} />
        <Divider />
        <MenuItem key="Manage users">
          <ListItemIcon>
            <GroupIcon fontSize="small" />
          </ListItemIcon>
          <Typography textAlign="center">
            <NavLinkStyled
              sx={{ marginLeft: 0 }}
              to="https://uat.zakaz.hdcem.ru/admin/t/user/"
            >
              Manage users
            </NavLinkStyled>
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem key="Logout" onClick={handleLogOut}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <Typography textAlign="center">Logout</Typography>
        </MenuItem>
        <Divider />
        <MenuItem key="Caption">
          <Typography variant="caption" textAlign="center">
            {copyright}
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UserMenu;
