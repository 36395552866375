import { Box, Button } from "@mui/material";
import StatusIcon from "../UI/StatusIcon/StatusIcon";

const FullScreenDeliveriesStatus = ({ status }) => {
  console.log(status);

  if (status === "invoiced") {
    return <Button>invoiced</Button>;
  }
  if (status === "intransit") {
    return (
      <>
        <Box paddingTop="2px">
          <StatusIcon small status="intransit" />
        </Box>
        in transit
      </>
    );
  }
};

export default FullScreenDeliveriesStatus;
