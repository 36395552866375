import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  ButtonBase,
  FormControl,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Popover from "@mui/material/Popover";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useActions } from "../../../../hooks/useActions";
import { useGetSitesQuery } from "../../../../redux/api/orderListApiSlice";

const SitesFilter = ({ label = "Projects / Sites" }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverWidth, setPopoverWidth] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    setPopoverWidth(e.currentTarget.offsetWidth);
  };
  const handleClose = () => setAnchorEl(null);

  const query = useSelector((state) => state.orderList.queryParams);
  const { data, isFetching } = useGetSitesQuery(query.customerId, {
    skip: !query.customerId,
  });

  const [options, setOptions] = useState([{ siteName: "All Sites" }]);
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const popoverContentProps = {
    popoverWidth,
    setSelectedOption,
    options,
    handleClose,
  };

  useEffect(() => {
    data && setOptions(data);
  }, [data]);

  return (
    <Grid item>
      <ButtonBase
        onClick={handleClick}
        sx={{
          display: "block",
          width: "100%",
        }}
      >
        <Paper
          square={false}
          elevation={0}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: (theme) => theme.palette.inputBackgroundColor.main,
            border: (theme) =>
              `1px solid ${theme.palette.inputBorderColor.main}`,
            padding: "5px",
            paddingBottom: "8px",
            paddingLeft: "12px",
            "&:hover": {
              background: "#E7E7E7",
            },
          }}
        >
          <Box maxWidth="90%" minWidth="222px">
            <Stack textAlign="left">
              <Typography variant="caption" color="text.secondary">
                {label}
              </Typography>
              <Typography variant="body1" noWrap>
                {isFetching ? "Loading..." : selectedOption.siteName}
              </Typography>
            </Stack>
          </Box>
          <Box>
            <ArrowDropDownIcon />
          </Box>
        </Paper>
      </ButtonBase>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ marginTop: 1 }}
      >
        <PopoverContent {...popoverContentProps} />
      </Popover>
    </Grid>
  );
};

const PopoverContent = ({
  popoverWidth,
  setSelectedOption,
  options,
  handleClose,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const handleSearchInput = (e) => setSearchInput(e.target.value);
  const { setQueryParams } = useActions();

  return (
    <Paper sx={{ width: popoverWidth, pl: 1, pt: 1, boxSizing: "border-box" }}>
      <FormControl fullWidth>
        <TextField
          value={searchInput}
          onChange={handleSearchInput}
          label="Search List"
          InputLabelProps={{ shrink: true }}
          variant="standard"
          id="site-search"
          aria-describedby="search site input"
          InputProps={{
            disableUnderline: false,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            sx: {
              "&.MuiInputBase-root": {
                backgroundColor: "white",
                borderColor: "white",
                mr: 1,
              },
            },
          }}
        />
      </FormControl>
      <List
        sx={{
          paddingBottom: 0,
          maxHeight: "250px",
          overflowY: "auto",
        }}
      >
        {options
          .filter((option) => {
            const inputArray = searchInput.toLowerCase().split(" ");
            const optionString = `${option.materialDescription}`.toLowerCase();
            return inputArray.every((item) => optionString.includes(item));
          })
          .map((option, index, options) => (
            <ListItem
              key={index}
              disablePadding
              divider={index < options.length - 1}
              sx={{
                alignItems: "flex-start",
              }}
            >
              <ListItemButton
                sx={{
                  alignItems: "flex-start",
                  pl: "5px",
                }}
                onClick={() => {
                  handleClose();
                  setSelectedOption(options[index]);
                  setQueryParams({
                    siteId: options[index].siteId,
                  });
                }}
              >
                <Box>
                  <Typography>{option.siteName}</Typography>
                  {option.street && (
                    <Typography display="block" variant="caption">
                      {option.street}
                    </Typography>
                  )}
                  {option.siteNumber && (
                    <Typography display="block" variant="caption">
                      {`ID:${option.siteNumber}`}
                    </Typography>
                  )}
                </Box>
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </Paper>
  );
};

export default SitesFilter;
