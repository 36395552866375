import { Box, Divider, Typography } from "@mui/material";
import { email } from "../../assets/img/images";
import {
  CaptionHeading,
  ConfirmationText,
  PinCodeHeadings,
} from "../UI/Typography/DeliveryOI";
import { useSelector } from "react-redux";

const OIPinCodeDetails = () => {
  const numberOfOrders = useSelector(
    (state) => state.deliveryOI.tableRows.length
  );
  return (
    <Box sx={{ padding: 2, marginTop: 2, marginBottom: 4 }}>
      <PinCodeHeadings>PIN Code sent</PinCodeHeadings>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",
          marginBottom: 2,
        }}
      >
        <ConfirmationText>
          {` Please check your email, ${numberOfOrders} ${
            numberOfOrders === 1 ? `email` : `emails`
          } sent`}
        </ConfirmationText>
        <Box>
          <img
            src={email}
            alt="Check your email"
            style={{ maxWidth: "50px" }}
          />
          <Box
            sx={{
              top: "4px",
              color: "white",
              right: "6px",
              width: "18px",
              height: "18px",
              display: "flex",
              padding: "0px",
              position: "absolute",
              fontSize: "12px",
              alignItems: "center",
              fontWeight: "bold",
              borderRadius: "10px",
              justifyContent: "center",
              backgroundColor: "orange",
            }}
          >
            {numberOfOrders}
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          columnGap: "15px",
          marginTop: 2,
        }}
      >
        <Box
          sx={{
            color: "white",
            width: "35px",
            height: "35px",
            display: "flex",
            alignItems: "center",
            borderRadius: "30px",
            justifyContent: "center",
            backgroundColor: "#11507B",
          }}
        >
          <Typography>HC</Typography>
        </Box>
        <Box>
          <CaptionHeading>subject:</CaptionHeading>
          <PinCodeHeadings>PIN Code for order</PinCodeHeadings>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CaptionHeading>from:</CaptionHeading>
            <Typography sx={{ fontSize: "14px" }}>
              noreply@hconnect.digital
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OIPinCodeDetails;
