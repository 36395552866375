import { apiSlice } from "./apiSlice";
const INVOICES = "invoices/";

export const orderListApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInvoices: builder.query({
      query: (arg) => ({
        url: INVOICES,
        params: { ...arg },
      }),
      providesTags: ["Invoices"],
    }),
  }),
});

export const { useGetInvoicesQuery } = orderListApiSlice;

export const { useLazyGetInvoicesQuery } = orderListApiSlice;
