import { MenuItem, Select } from "@mui/material";

const NumberFilterSelect = ({ selectValue, handleSelectChange }) => {
  return (
    <Select
      id="number-type-filter"
      label="numberType"
      labelId="number-type-filter-label"
      defaultValue="Invoice"
      placeholder="Invoice"
      value={selectValue}
      onChange={handleSelectChange}
      variant="standard"
      disableUnderline
      sx={(theme) => ({
        borderLeft: `1px solid ${theme.palette.inputBorderColor.main}`,
        margin: "8px 0",
        paddingLeft: "15px",
        minWidth: "100px",
      })}
      MenuProps={{
        sx: {
          "& .MuiMenu-list": {
            background: "#001E2A",
            color: "white",
          },
        },
      }}
    >
      <MenuItem value="Invoice">Invoice</MenuItem>
      <MenuItem value="Ticket">Ticket</MenuItem>
    </Select>
  );
};

export default NumberFilterSelect;
