import Paper from "@mui/material/Paper";
import { keyframes } from "@mui/system";
import { useDetailsCard } from "hooks/useDetailsCard";
import { useGetTableData } from "hooks/useGetTableData";
import { useSelector } from "react-redux";
import InvoiceDetails from "../../Invoices/InvoiceDetails";
import OrderDetails from "../../OrderList/OrderDetails";
import NoDataOverlay from "../NoDataOverlay/NoDataOverlay";

const ItemDetails = () => {
  const { data, isInvoices } = useGetTableData();
  const { isCardOpen } = useDetailsCard();

  const currentOrderId = useSelector((state) => state.orderList.orderId);
  const currentOrderData = data?.results.length && data.results[currentOrderId];

  const slideCard = keyframes`from {
            right: -100%;
        }
        to {
            right: 0;
        }
        `;

  const renderDetails = () => {
    if (!currentOrderData || !isCardOpen) return <NoDataOverlay />;
    return isInvoices ? (
      <InvoiceDetails currentOrderData={currentOrderData} />
    ) : (
      <OrderDetails currentOrderData={currentOrderData} />
    );
  };

  return (
    <Paper
      elevation={4}
      sx={(theme) => ({
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        position: "sticky",
        top: "10px",
        overflowY: "auto",
        height: "600px",
        [theme.breakpoints.down("lgr")]: {
          display: !isCardOpen && "none",
          position: "fixed",
          top: "200px",
          right: "10px",
          minWidth: "300px",
          width: "40%",
          animation: `${slideCard} .2s`,
          zIndex: "6",
        },
      })}
    >
      {renderDetails()}
    </Paper>
  );
};

export default ItemDetails;
