import { Box } from "@mui/material";
import { OverviewBoxHeadingStyled } from "../Shared";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const CreditWidgetTopText = ({ payer, payerNumber }) => {
  return (
    <Box
      sx={{
        display: "flex",
        columnGap: 1,
        alignItems: "center",
      }}
    >
      <OverviewBoxHeadingStyled>
        Account balance for payer {payer} {payerNumber}
      </OverviewBoxHeadingStyled>
      <InfoOutlinedIcon
        fontSize="small"
        sx={{
          cursor: "pointer",
          color: (theme) => theme.palette.text.secondary,
        }}
      />
    </Box>
  );
};

export default CreditWidgetTopText;
