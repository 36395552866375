import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Typography } from "@mui/material";
import { localizeCurrency } from "helpers/helpers";
import { CaptionHeading } from "../../UI/Typography/SharedTypography";
import { OverviewBoxHeadingStyled } from "../Shared";

const CreditWidgetLegend = ({
  name,
  amount,
  currency,
  color,
  dispatched,
  exceededBy,
}) => {
  const isExceeded = exceededBy > 0;

  return (
    <Box>
      <Box sx={{ paddingX: 1 }}>
        <Box sx={{ background: color, width: "100%", height: "4px" }}></Box>
        <CaptionHeading sx={{ textTransform: "uppercase", fontWeight: 700 }}>
          {name}
        </CaptionHeading>
        <OverviewBoxHeadingStyled sx={{ color: isExceeded && "red" }}>
          {localizeCurrency(isExceeded ? 0 : amount, currency)}
        </OverviewBoxHeadingStyled>
      </Box>
      {dispatched && (
        <OverviewBoxHeadingStyled sx={{ paddingX: 1 }}>
          {dispatched}
        </OverviewBoxHeadingStyled>
      )}
      {isExceeded && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: 1,
            color: "red",
            paddingX: "12px",
          }}
        >
          <InfoOutlinedIcon fontSize="small" />
          <Box sx={{ "&>p": { fontSize: "12px" } }}>
            <Typography>Exceeded by</Typography>
            <Typography>{localizeCurrency(exceededBy, currency)}</Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CreditWidgetLegend;
