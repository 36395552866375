import { Typography } from "@mui/material";
import {
  lastMonthEnd,
  lastMonthStart,
  today,
  weekFromToday,
} from "constants/dates";
import { formattedDateRange } from "helpers/helpers";
import StyledCalendarToggleButton from "./StyledCalendarToggleButton";

const SelectionOrders = ({ setLastMonth, setOneWeek, setToday }) => {
  return (
    <>
      <StyledCalendarToggleButton
        value="week"
        aria-label="one week"
        onClick={setOneWeek}
      >
        <Typography>One Week</Typography>
        <Typography>{`${formattedDateRange(today, weekFromToday)}`}</Typography>
      </StyledCalendarToggleButton>
      <StyledCalendarToggleButton
        value="today"
        aria-label="today"
        onClick={setToday}
      >
        <Typography>Today</Typography>
        <Typography>{formattedDateRange(today)}</Typography>
      </StyledCalendarToggleButton>
      <StyledCalendarToggleButton
        value="last month"
        aria-label="last month"
        onClick={setLastMonth}
      >
        <Typography>Last month</Typography>
        <Typography>
          {formattedDateRange(lastMonthStart, lastMonthEnd)}
        </Typography>
      </StyledCalendarToggleButton>
    </>
  );
};

export default SelectionOrders;
