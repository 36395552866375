import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { timeOptions } from "constants/constants";
import { findClosestTime, findCurrentIndex } from "helpers/helpers";
import TimeInput from "../../UI/TimeInput/TimeInput";
import { useFormContext } from "react-hook-form";

const TimePickerOwn = ({
  time = { defaultEarliestLoadTime: "13:00", defaultLatestLoadTime: "23:00" },
}) => {
  const [timeEarliest, setTimeEarliest] = useState(
    findClosestTime(time?.defaultEarliestLoadTime)
  );
  const [earliestIndex, setEarliestIndex] = useState(
    findCurrentIndex(timeEarliest)
  );

  const [timeLatest, setTimeLatest] = useState(
    findClosestTime(time?.defaultLatestLoadTime)
  );
  const [latestIndex, setLatestIndex] = useState(findCurrentIndex(timeLatest));

  const timeOptionsLastIndex = timeOptions.length - 1;

  useEffect(() => {
    if (earliestIndex === latestIndex) {
      latestIndex === timeOptionsLastIndex
        ? setEarliestIndex(timeOptionsLastIndex - 1)
        : setLatestIndex((prev) => prev + 1);
    }

    if (earliestIndex > latestIndex) {
      if (earliestIndex === timeOptionsLastIndex) {
        setLatestIndex(timeOptionsLastIndex);
      } else {
        setLatestIndex(earliestIndex + 1);
      }
    }

    if (latestIndex === 0) {
      setLatestIndex(earliestIndex + 1);
    }
  }, [earliestIndex, latestIndex, timeOptionsLastIndex]);

  useEffect(() => {
    setEarliestIndex(findCurrentIndex(time?.defaultEarliestLoadTime));
    setLatestIndex(findCurrentIndex(time?.defaultLatestLoadTime));
  }, [time?.defaultEarliestLoadTime, time?.defaultLatestLoadTime]);

  const { setValue } = useFormContext();

  useEffect(() => {
    setValue("defaultDeliveryWindow", {
      defaultEarliestLoadTime: timeEarliest,
      defaultLatestLoadTime: timeLatest,
    });
  }, [setValue, timeEarliest, timeLatest]);
  return (
    <>
      <TimeInput
        label="earliest"
        id="startTime"
        time={timeEarliest}
        setTime={setTimeEarliest}
        index={earliestIndex}
        setIndex={setEarliestIndex}
        options={timeOptions}
      />
      <Box marginBottom={2} />
      <TimeInput
        label="latest"
        id="endTime"
        time={timeLatest}
        setTime={setTimeLatest}
        index={latestIndex}
        setIndex={setLatestIndex}
        options={timeOptions.slice(earliestIndex + 1)}
      />
    </>
  );
};

export default TimePickerOwn;
