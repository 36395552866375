import { Box } from "@mui/material";
import PropTypes from "prop-types";

export const CustomTabPanel = (props) => {
  const { children, value, index, isTimeTabs = false, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`delivery-tabpanel-${index}`}
      aria-labelledby={`delivery-tab-${index}`}
      {...other}
    >
      {isTimeTabs ? (
        value === index && <Box sx={{ mt: 2 }}>{children}</Box>
      ) : (
        <Box sx={{ mt: 2 }}>{children}</Box>
      )}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export const a11yProps = (index) => {
  return {
    id: `delivery-form-tab-${index}`,
    "aria-controls": `delivery-tabpanel-${index}`,
  };
};
