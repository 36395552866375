import { apiSlice } from "./apiSlice";

export const legalApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLegal: builder.query({
      query: (page) => `${page}/`,
    }),
  }),
});

export const { useGetLegalQuery } = legalApiSlice;
