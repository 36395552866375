import DateRangeIcon from "@mui/icons-material/DateRange";
import { Box, Button, InputAdornment, TextField } from "@mui/material";
import Popover from "@mui/material/Popover";
import { formattedDateRange } from "helpers/helpers";
import { useActions } from "hooks/useActions";
import { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useSelector } from "react-redux";
import "./DateRangeFilter.css";
import QuickSelection from "./QuickSelection";

const DateRangeFilter = () => {
  const query = useSelector((state) => state.orderList.queryParams);
  const initialDateStart = new Date(query.shippingDate);
  const initialDateEnd = new Date(query.endDate);

  const { setQueryParams } = useActions();

  const [anchorEl, setAnchorEl] = useState(null);
  const openPopover = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [textDateRange, setTextDateRange] = useState(
    formattedDateRange(initialDateStart, initialDateEnd)
  );

  const [presetPeriod, setPresetPeriod] = useState("left");
  const [chosenPeriod, setChosenPeriod] = useState([
    initialDateStart,
    initialDateEnd,
  ]);
  const [activeDate, setActiveDate] = useState(null);

  const setDate = (e) => {
    setTextDateRange(formattedDateRange(e[0], e[1]));
    setChosenPeriod([e[0], e[1]]);
  };

  const cancelPresetDate = () => {
    setActiveDate(null);
    setPresetPeriod(null);
  };

  const sendDate = () => {
    handleClose();
    setQueryParams({
      shippingDate: new Date(chosenPeriod[0]).toISOString(),
      endDate: new Date(chosenPeriod[1]).toISOString(),
    });
  };

  return (
    <Box>
      <Button sx={{ display: "contents" }}>
        <TextField
          onMouseDown={(e) => e.preventDefault()}
          onClick={openPopover}
          variant="filled"
          label="Date"
          value={textDateRange}
          sx={{
            input: { cursor: "pointer" },
            ".MuiInputBase-root": {
              cursor: "pointer",
            },
          }}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <DateRangeIcon />
              </InputAdornment>
            ),
          }}
        />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ backgroundColor: "#001E2A", color: "white" }}>
          <Box sx={{ display: "flex" }}>
            <Calendar
              selectRange
              onActiveStartDateChange={() => setActiveDate(null)}
              onChange={setDate}
              value={chosenPeriod}
              onClickDay={cancelPresetDate}
              activeStartDate={activeDate}
            />
            <QuickSelection
              setTextDateRange={setTextDateRange}
              setChosenPeriod={setChosenPeriod}
              setActiveDate={setActiveDate}
              presetPeriod={presetPeriod}
              setPresetPeriod={setPresetPeriod}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={sendDate} sx={{ margin: "16px" }}>
              Apply
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default DateRangeFilter;
