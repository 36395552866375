import AdbIcon from "@mui/icons-material/Adb";
import GroupIcon from "@mui/icons-material/Group";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import TranslateIcon from "@mui/icons-material/Translate";
import { Divider, ListItemIcon } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { HCLogo, HubLogo } from "../../assets/img/images";
import { useLogoutMutation } from "../../redux/api/authApiSlice";
import NavLinkStyled from "../UI/NavLinkStyled/NavLinkStyled";
import LegalMenu from "./LegalMenu";
import { useGetUserQuery } from "../../redux/api/userApiSlice";
import { useNavigate } from "react-router-dom";

const pages = ["Overview", "Orders & Deliveries", "Finance"];
const pageLinks = ["overview", "orders", "finance"];

const AppHeader = ({ children }) => {
  const navigate = useNavigate();
  const { data } = useGetUserQuery(localStorage.getItem("userId"));

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const [logout] = useLogoutMutation();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogOut = () => {
    handleCloseUserMenu();
    logout();
    navigate("/login");
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "white",
          width: "100vw",
        }}
      >
        <Container
          sx={{
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: { xs: "0" },
            maxWidth: { xs: "none" },
            paddingX: { xs: 0, lg: "20px" },
          }}
        >
          <Toolbar
            sx={{
              width: "100vw",
            }}
          >
            <Typography
              variant="h6"
              noWrap
              component="a"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
                marginLeft: "15px",
              }}
            >
              <img src={HCLogo} alt="logo" height="24px" />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon
                  fontSize="small"
                  sx={{
                    color: "black",
                    borderRadius: "100%",
                    padding: "10px",
                    boxShadow: "-2px 0px 8px rgba(0, 0, 0, 0.2)",
                  }}
                />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                disableScrollLock
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page, index) => (
                  <NavLinkStyled
                    key={pageLinks[index]}
                    to={`/${pageLinks[index]}`}
                  >
                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                      <Typography color={"#00374d"} textAlign="center">
                        {page}
                      </Typography>
                    </MenuItem>
                  </NavLinkStyled>
                ))}
              </Menu>
            </Box>
            <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
            <img src={HubLogo} alt="logo" height="24px" />
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              LOGO
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page, index) => (
                <NavLinkStyled
                  key={pageLinks[index]}
                  to={`/${pageLinks[index]}`}
                >
                  {page}
                </NavLinkStyled>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0, paddingRight: { xs: 0, lg: "10px" } }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="User Avatar" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                id="menu-appbar"
                disableScrollLock
                keepMounted
                anchorEl={anchorElUser}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{ mt: "45px" }}
              >
                <MenuItem
                  sx={{
                    padding: "18px 24px",
                    textAlign: "center",
                    flexDirection: "column",
                  }}
                >
                  <Avatar
                    alt={data?.name}
                    src="/static/images/avatar/2.jpg"
                    sx={{ margin: "0 auto" }}
                  />
                  <Typography>{data?.name}</Typography>
                  <Typography>{data?.eMail}</Typography>
                </MenuItem>
                <Divider />
                <MenuItem key="English">
                  <ListItemIcon>
                    <TranslateIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography textAlign="center">English</Typography>
                </MenuItem>
                <Divider />
                <LegalMenu closeMenu={handleCloseUserMenu} />
                <Divider />
                <MenuItem key="Manage users">
                  <ListItemIcon>
                    <GroupIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography textAlign="center">
                    <NavLinkStyled
                      sx={{ marginLeft: 0 }}
                      to="https://uat.zakaz.hdcem.ru/admin/t/user/"
                    >
                      Manage users
                    </NavLinkStyled>
                  </Typography>
                </MenuItem>
                <Divider />
                <MenuItem key="Logout" onClick={handleLogOut}>
                  <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
                <Divider />
                <MenuItem key="Caption">
                  <Typography variant="caption" textAlign="center">
                    {`© Copyright ${new Date().getFullYear()} HeidelbergCement`}
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {children}
    </>
  );
};
export default AppHeader;
