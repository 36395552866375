import { timeOptions } from "constants/constants";
import moment from "moment";

export const formatOnlyNumbers = (timeString) => {
  return Number(timeString.replace(/\D/g, ""));
};

export const formatTime = (initialTime) => {
  if (!initialTime) return "00:00";
  const hours = Math.floor(initialTime / 3600);
  const minutes = Math.floor((initialTime % 3600) / 60);
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}`;
};

export const addSemicolon = (timeString) => {
  const onlyNumbers = timeString.replace(/\D/g, "");
  const matches = onlyNumbers.match(/^(\d{0,2})?(\d{0,2})/);
  const formatedInputTime =
    matches && [matches[1], matches[2] ? ":" : "", matches[2]].join("");

  return formatedInputTime;
};

export const getAvailableDate = (date) => {
  if (moment(date).isAfter()) {
    return date;
  } else {
    return moment().add(1, "days").format("YYYY-MM-DD");
  }
};

export const findClosestTime = (timeString) => {
  const timeArray = timeString.split(":");

  if (!timeArray[0]) timeArray[0] = "00";
  if (!timeArray[1]) timeArray[1] = "00";

  const numHours = Number(timeArray[0]);
  const numMinutes = Number(timeArray[1]);

  if (numHours === 23 && numMinutes > 44) {
    timeArray[1] = "59";
  } else if (numMinutes > 14) {
    timeArray[1] = "30";
  } else if (numMinutes > 44) {
    timeArray[1] = "00";
    timeArray[0] = numHours + 1;
  } else {
    timeArray[1] = "00";
  }

  const closestTime = timeOptions.find(({ label24h }) =>
    label24h.includes(`${timeArray[0]}:${timeArray[1]}`)
  );
  return closestTime?.label24h;
};

export const findCurrentIndex = (timeValue) => {
  const currentIndex = timeOptions.findIndex(
    (time) => time.label24h === findClosestTime(timeValue)
  );

  return currentIndex;
};

export const sortByMaterial = (acc, item) => {
  if (!acc || !item) return;
  return {
    ...acc,
    [item.materials?.materialDescription]: {
      orders: [
        ...(acc[item.materials?.materialDescription]?.orders || []),
        item,
      ],
      total:
        (acc[item.materials?.materialDescription]?.total || 0) +
        item?.truckCapacity.capacity,
    },
  };
};

export const localizeDate = (string) => new Date(string).toLocaleDateString();
export const localizeTime = (string) =>
  new Date(string).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

export const localizeCurrency = (amount, currency) =>
  new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency: currency,
  }).format(amount);

export const handleGetfile = async (href, type) => {
  const response = await fetch("/api/v1/" + href, {
    headers: { Authorization: `Token ${localStorage.getItem("token")}` },
  });
  if (!response.ok) return;

  const blob = await response.blob();
  const url = window.URL.createObjectURL(new Blob([blob], { type }));

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "download");
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
};

export const formattedDateRange = (...dates) => {
  const dateOne = dates[0] && new Date(dates[0]);
  const dateTwo = dates[1] && new Date(dates[1]);
  if (dates.length === 1) {
    return new Intl.DateTimeFormat().format(dateOne);
  }
  if (dates.length > 1 && dateOne.getDate() === dateTwo.getDate()) {
    return new Intl.DateTimeFormat().format(dateOne);
  }
  return `${new Intl.DateTimeFormat().format(
    dateOne
  )}-${new Intl.DateTimeFormat().format(dateTwo)}`;
};

export const calculatedPage = (offset, limit) => Math.floor(offset / limit);
export const dateComparator = (a, b) =>
  new Date(a.date).getDate() - new Date(b.date).getDate();
export const calculatedWidth = (amount, total) => `${(amount / total) * 100}%`;
