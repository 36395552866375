import { Box, Button, Grid, Typography } from "@mui/material";
import { PageHeading } from "../UI/Typography/SharedTypography";
import WhiteOutlineButton from "../UI/WhiteOutlineButton/WhiteOutlineButton";
import {
  OverviewBoxHeadingStyled,
  OverviewBoxStyled,
  OverviewButtonBoxStyled,
  OverviewGridContainerStyled,
} from "./Shared";

const OrdersGrid = () => {
  return (
    <>
      <PageHeading sx={{ marginY: 4 }}>Orders</PageHeading>
      <OverviewGridContainerStyled>
        <Grid item md={3} xs={12}>
          <OverviewBoxStyled>
            <OverviewBoxHeadingStyled>
              schedule new delivery
            </OverviewBoxHeadingStyled>
            <OverviewButtonBoxStyled>
              <Button variant="contained">schedule</Button>
            </OverviewButtonBoxStyled>
          </OverviewBoxStyled>
        </Grid>
        <Grid item md={3} xs={12}>
          <OverviewBoxStyled>
            <Box>
              <OverviewBoxHeadingStyled>
                Generate PIN Code for pick up
              </OverviewBoxHeadingStyled>
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ lineHeight: "14px" }}
              >
                Save time and money. Click here to generate a PIN code a driver
                can use to pick up your material.
              </Typography>
            </Box>
            <OverviewButtonBoxStyled>
              <Button variant="contained">Generate Pin Code</Button>
            </OverviewButtonBoxStyled>
          </OverviewBoxStyled>
        </Grid>
        <Grid item md={6} xs={12}>
          <OverviewBoxStyled>
            <OverviewBoxHeadingStyled>
              Orders by status
            </OverviewBoxHeadingStyled>
            <OverviewButtonBoxStyled>
              <WhiteOutlineButton>Upcoming</WhiteOutlineButton>
              <Button variant="contained">Today</Button>
            </OverviewButtonBoxStyled>
          </OverviewBoxStyled>
        </Grid>
      </OverviewGridContainerStyled>
    </>
  );
};

export default OrdersGrid;
