import { Box, Paper } from "@mui/material";
import parse from "html-react-parser";
import ToolBar from "../Components/UI/ToolBar/ToolBar";
import { useGetLegalQuery } from "../redux/api/legalApiSlice";
import LegalPageOssCard from "../Components/LegalPage/LegalPageOssCard";

const LegalPage = ({ page = "terms" }) => {
  const { data, isFetching, isError, isSuccess } = useGetLegalQuery(page);

  console.log(page);
  console.log("-------------------------------------------------");
  console.log(isSuccess);
  console.log(isFetching);
  console.log(data);
  console.log("-------------------------------------------------");

  return (
    <>
      <ToolBar text="Back" />
      <Box sx={{ background: "white", height: "100%" }}>
        <Paper sx={{ maxWidth: "1200px", margin: "0 auto", padding: "30px" }}>
          {isFetching && `Loading...`}
          {isError && `There was an error loading this page`}
          {isSuccess &&
            !isFetching &&
            (page === "oss"
              ? data?.results.map((lib) => (
                  <LegalPageOssCard lib={lib} key={lib.library} />
                ))
              : parse(data.text))}
        </Paper>
      </Box>
    </>
  );
};

export default LegalPage;
