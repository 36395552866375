import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Box, CardContent, Grid, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import { localizeDate } from "../../helpers/helpers";
import { OrderCardHeading, OrderCardText } from "../UI/Typography/OrderCard";
import FullScreenDeliveriesStatus from "./FullScreenDeliveriesStatus";
import FullScreenGridItem from "./FullScreenGridItem";

const iconStyles = { display: "flex", columnGap: "5px" };

const FullscreenDeliveriesMobile = ({ deliveries }) => {
  return (
    <Box>
      <Paper
        elevation={0}
        sx={{
          border: (theme) => `1px solid ${theme.palette.inputBorderColor.main}`,
          background: (theme) => theme.palette.inputBackgroundColor.main,
        }}
      >
        <CardContent sx={{ padding: 2 }}>
          <Grid container spacing={2}>
            <FullScreenGridItem>
              <OrderCardHeading>Ticket</OrderCardHeading>
              <Link href="#" variant="body2" sx={iconStyles}>
                <PictureAsPdfIcon fontSize="small" />
                {deliveries.deliveryNumber}
              </Link>
            </FullScreenGridItem>
            <FullScreenGridItem sx={{ display: "flex", columnGap: "5px" }}>
              <FullScreenDeliveriesStatus status={deliveries.deliveryStatus} />
            </FullScreenGridItem>
            <FullScreenGridItem xs={12}>
              <OrderCardHeading>Left At</OrderCardHeading>
              <OrderCardText>
                {localizeDate(deliveries.shippingDate)}
              </OrderCardText>
            </FullScreenGridItem>
            <FullScreenGridItem>
              <OrderCardHeading>QTY Loaded</OrderCardHeading>
              <OrderCardText>{`${deliveries.loadQuantity} ${deliveries.loadQuantityUom}`}</OrderCardText>
            </FullScreenGridItem>
            <FullScreenGridItem>
              <OrderCardHeading>Truck</OrderCardHeading>
              <OrderCardText>{deliveries.truckLicensePlate}</OrderCardText>
            </FullScreenGridItem>
            <FullScreenGridItem>
              <OrderCardHeading>Plant</OrderCardHeading>
              <OrderCardText>{deliveries.plantName}</OrderCardText>
            </FullScreenGridItem>
          </Grid>
        </CardContent>
      </Paper>
    </Box>
  );
};

export default FullscreenDeliveriesMobile;
